@import "src/assets/styles/variable.scss";
.footer_main {
  .container {
    padding: 28px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer_landing{
      display: flex;
      flex-wrap: wrap;
      gap: 88px;
      p{
        font-family: Gilroy,sans-serif ;
        color: #606168;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
      }
    }
    .footer_center{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 640px;
      width: 100%;
     p{
       font-family: Gilroy,sans-serif ;
       color: #606168;
       font-size: 14px;
       line-height: 17px;
       cursor: pointer;
     }
    }
  }

  .footer_main_FAQ{
    color: #606168;
    font-size: 14px;
    font-weight: 400;

  }
}

.footer_main_left {
  img {
    cursor: pointer;
  }
}
.footer_main_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: normal;
    font-size: 14px;
    color: #606168;
    margin-right: 25px;
    cursor: pointer;
    &:hover {
      color: $primary_one;
    }
    &:active {
      color: $primary_one_active;
    }
  }
  div {
    font-weight: normal;
    margin-left: auto;
    font-size: 14px;
    color: #c4c4c4;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .footer_main {
    .container {
      border: none;
      margin: 0 auto;
      flex-direction: column;
      .footer_main_left{
        .bianu_logo_container{
          margin-right: 0;
        }
      }
      .footer_landing{
        gap: 20px;
        justify-content: center;
        margin: 0 0 15px 0 ;
      }
      .footer_center{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
      }
    }
  }
  .footer_main_right {
    flex-direction: column;
    p {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .div{
      text-align: center;
    }
  }

  .footer_main_left {
    margin-bottom: 24px;
  }


}
