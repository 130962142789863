@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GilroyBold';
  src: url('assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GilroySemi';
  src: url('assets/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GilroyExtraBold';
  src: url('assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GilroyLight';
  src: url('assets/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GilroyBlack';
  src: url('assets/fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GilroyHeavy';
  src: url('assets/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.App {
  height: 100%;
  width: 100%;
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Gilroy', serif;
    -webkit-font-smoothing: antialiased;
  }
  h1 {
    font-family: 'GilroyBold', serif;
  }
  button {
    font-family: 'GilroySemi', serif;
  }
  //p {
  //  font-family: 'GilroySemi', serif !important;
  //}
}
