@import 'src/assets/styles/variable';

.confirm-form {
  h1 {
    width: 425px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 32px;
    min-height: 40px;
  }
  img {
    width: 400px;
    place-self: center;
  }

  button {
    width: 400px;
    place-self: center;

    @media (max-width: 600px) {
      width: 253px;
    }
  }
  @media screen and (max-width: 601px) {
    img {
      margin-top: 20px;
    }
  }
}

.review-terms-wrapper {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    margin: 0;
  }
  h1 {
    width: 100%;
    margin-bottom: 32px;
  }
  p {
    width: 480px;
    text-align: center;
  }
  .bottom-wrapper {
    margin-top: 32px;
    margin-bottom: 55px;
    width: 480px;
    place-self: center;
    text-align: center;
    font-size: 20px;
    .review-terms-link {
      color: $primary_one;
      cursor: pointer;
      border-bottom: 2px solid $primary_one;
    }
  }
}


