@import "src/assets/styles/variable";

.payment-summary-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: min-content;
  gap: 16px 85px;
  min-width: 330px;
  h1 {
    text-align: left;
    grid-column: span 2;
    font-size: 31px;
    line-height: 36px;
    font-weight: 700;
    font-family: Gilroy,sans-serif;
  }
  .row {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
}
