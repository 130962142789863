.register-form-info {
  font-size: 18px;
  font-family: GilroyRegular, sans-serif;
  font-weight: 400;
  line-height: 25px;
  margin: 0 auto;
  display: block;
  max-width: 1000px;
  width: 100%;
}

.register-form-header {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 16px;
}

.register-main-form {
  max-width: 1000px;
  width: 100%;
  margin: 78px auto 40px;
  display: flex;
  gap: 88px
}

.register-form-input {
  max-width: 448px;
  width: 100%;

}

.form-container {
  max-width: 448px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.terms_info {
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: baseline;
}

.terms_info_text {
  font-family: GilroyRegular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 0 20px 0 0;

  span {
    color: #258278;
    text-decoration: underline;
    cursor: pointer;
  }
}

.terms_info {
  display: flex;

  .terms_info_text {
    margin-left: 8px;
  }

  .round_monthly label:after {
    border: 3px solid #29BF2F;
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 8px;
  }

  .round_monthly input[type="checkbox"] {
    visibility: hidden;
  }

  .round_monthly input[type="checkbox"]:checked + label {
    background-color: #fff;
  }

  .round_monthly input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .round_monthly {
    position: relative;
    margin-left: 8px;
  }

  .round_monthly label {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 20px;
    right: 0;
    position: absolute;
    bottom: 0;
    width: 20px;
  }
}

@media(max-width: 600px) {
  .register-main-form {
  flex-wrap: wrap;
    margin: 25px auto 15px;
    text-align: left;
    gap: 40px;
  }
  .register-form-header, .form-container_name, .form-container_details{
    text-align: left !important;
  }
  .register-form-info{
    max-width: 448px;
    margin: 0;
  }
  .terms_info{
    margin-left: 14px;
  }
}