
.main_container{
  width: 340px;
  height: auto;
  background-color:#F5F5F5;
  padding: 30px 30px;
  position: absolute;
  top:40px;
  right: -35px;
  border-radius: 16px;
  z-index: 100;
  .loader_icon{
    max-width: 55px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  &::before {
    position: absolute;
    content: '';
    top: -6px;
    right: 34px;
    z-index: 66;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    transform: rotate(45deg);
    background-color:#F5F5F5;
  }
}
.main_container_head{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.main_container_head p{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #383942;
}
.main_container_head img{
  width: 20px;
  height: 20px;
}
.content_cart_subscribed {
  padding: 10px 10px;
  text-align: center;
}
.main_container_content{
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(196, 196, 196, 0.3);
  box-sizing: border-box;
  border-radius: 12px;
  .cart_icons_img{
    max-width: 47px;
    width: 100%;
  }
}
.main_container_content_p{
  max-width: 100%;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.main_container_content img{
  margin-right: 10px;
  border-radius: 50%;
}
.main_container_content p{
  max-width: 180px ;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.main_container_content_item1{
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  img{
    width: 47px;
    height: 47px;
    border-radius: 50%;
  }
}
.main_container_content_item2,.main_container_content_item3 {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
}
.main_container_subtotal{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.main_container_subtotal_item{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cart_button_wrapper {
  button {
    width: 100%;
  }
}
.subscription_total {
  max-width: 560px;
  width: 100%;
}
.main_container_subtotal_item_price{
  display: flex;
}
.main_container_subtotal_item_price p{
  margin-right: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #383942;
}
.subtotal{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #383942;
}
.total{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #606168;
  margin: 8px 0 24px 0;
}
.main_container_button{
  width: 300px;
  height: 56px;
  background: #006C61;
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  &:hover{
    background: #28977D;
  }
  &:active{
  background: #034942;
  }
  &disabled {
    background: #006C61;
  }
}
