@font-face {
  font-family: 'GilroySemi';
  src: url('../../../assets/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.single-form {
  //max-width: 600px;
  text-align: center;
  h1 {
    line-height: 125%;
    width: 650px;
  }

  .yes-no-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    button {
      text-transform: none;
      width: min-content;
      padding: 32px;
      margin-left: 10px;
      max-height: 92px;
      font-size: 18px;
    }
  }

  .button-container {
    place-self: center;
    white-space: nowrap;
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    button {
      text-transform: none;
      width: max-content;
      line-height: 22px;
      font-family: 'GilroySemi', sans-serif;
      //font-weight: bold;
      text-align: start;
      padding: 20px;
      max-height: 63px;
      font-size: 18px;
      letter-spacing: normal;
    }
  }

  .two-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;

    button {
      text-transform: none;
      padding: 32px;
      margin-left: 10px;
      max-height: 92px;
      font-size: 18px;
    }
  }

  .bottom-text-wrapper {
    place-self: center;
    width: 505px;
text-align: left;
    p {
      font-size: 16px;
      margin-bottom: 12px;
    }

    ul {
      height: 72px;
      list-style: inside;
      margin: 0;

      li {
        margin: 0;
        font-size: 14px;
        line-height: 17.5px;
      }
    }
  }

  @media screen and (max-width: 601px) {
    .button-container {
      flex-wrap: wrap;
      gap: 10px;
    }
    .button-container button {
      max-height: 70px;
      font-size: 12px;
      line-height: 12px;
      white-space: pre-line;
      margin: 0;
    }
    .bottom-text-wrapper ul {
      margin-bottom: 40px;
    }
  }
}
