@import "../../assets/styles/variable";

ul {
  list-style: circle;
}

.payment-details-header {
  margin: 64px 0 32px 0;
}

.recommendation {
  //max-width: 1221px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .footer_main{
    .footer_center{
      max-width: 340px;
      width: 100%;
    }
  }
  .recommendation-details_nameOfSupMob{
    display: none;
  }
  .login_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 100px;

    .div {
      width: 55px;
    }
  }
}

//rec details screen
.blackout {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .2);
  z-index: 5;
}
.recommendation-details {
  position: fixed;
  right: 0;
  overflow: scroll;
  top: 0;
  height: 100vh;
  background-color: $white;
  max-width: 629px;
  width: 100%;
  z-index: 40;
  padding: 35px 30px;
  transition: all .3s linear;
}

.recommendation-closeIcon {
  cursor: pointer;
}

.recommendation-details_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.recommendation-details_content {
  max-width: 320px;
  width: 100%;
  padding-top: 25px;
.primary_button_green{
  margin: 10px 0 0 16px;
}
  p {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 22px;
  }
}

.recommendation-details_headerList {
  padding-left: 18px;
  list-style-type: disc;

  li {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $secondary_one_hover_text;
  }
}

.recommendation-details_text {
  font-size: 16px;
  line-height: 24px;
  color: $secondary_one_hover_text;
  padding-bottom: 20px;
  font-weight: 400 !important;
}

.recommendation-details_advantages {
  .recommendation_benefit{
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
    .recommendation_benefit_icon{
      background: #c4c4c4;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    display: grid;
    grid-template: 1fr 1fr/1fr 1fr;
    place-items: center;

    img {
      margin-left: 15px;
    }
  }

  li {
    line-height: 21px;
    font-size: 14px;
    color: $secondary_one_hover_text;
  }
}

//end of details screen
.recommendation-header_title {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  padding: 40px 0 80px 0;

  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
}


.recommendation-supplements_title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 22px;
}

.recommendation-supplements {
  max-width: 1238px;
  width: 100%;
  margin: 0 auto;
}


.recommendation-supplements_items {
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  overflow-x: auto;
  max-width: 1238px;

}

.recommendation-details_logo {
  width: 182px;
  height: 182px;
  object-fit: cover;
  border-radius: 50%;
  align-self: center;
}

.recommendation-details_whatInside{
  font-weight: 700;
  font-size: 24px;
  margin-top: 15px;
}

.recommendation-details-comprisesImg{
  max-width: 100%;
  margin-top: 30px;
}
.supplements-item {
  max-width: 287px;
  width: 100%;
  border: 1px solid $border_color;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}
.supplements_list_recommended{
  position: absolute;
  font-size: 12px;
  color: $white;
  background-color: $primary_one;
  text-transform: uppercase;
  border-radius: 12px;
  line-height: 16px;
  padding: 3px 8px;
  top: 8px  ;
  left: 10px;
}

.supplements-item_img {
  max-width: 287px;
  max-height: 180px;
  object-fit: cover;
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.supplements-item_text {
  padding: 10px 33px 30px;
}

.supplements-item_title {
  padding: 20px 0 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.supplements-item_list {
  font-size: 14px;
  line-height: 22px;
  color: $color;
  list-style: disc;
}

.recommendation-options {
  display: flex;
  margin: 24px auto 0;
}



.recommendation-selected {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 17px;
  margin: 0 4px;
  border-radius: 100px;
  color: $secondary_two;
  background-color: rgba(0, 108, 97, 0.1);
}

.recommendation-deselected {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 17px;
  margin: 0 4px;
  border-radius: 100px;
  background-color: $secondary_one;
  color: $account_side_menu;
}
@media (max-width: 600px) {
  .recommendation-details_header {
    flex-direction: column;

  }
  .recommendation {
   padding: 0 0;
    .footer_main{
      display: none;
    }

    .recommendation-details_nameOfSupMob{
      display: block;
      text-align: center;
      margin-bottom: 32px;
    }
    .recommendation-details_content{
      .recommendation-details_nameOfSup{
        display: none;
      }
    }
  }
  .recommendation-details_content{
    .primary_button_green{
      margin: 10px 0 0;
    }
  }
}
