.modal_terms{
  background-color: #38394350;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .modal_terms_content{
    .terms_content_title{
      margin-top: 0;
    }
  }

}
.modal_terms_wrapper{
  height: 90%;
  overflow: scroll;
  margin-top: 75px;
}
.modal_terms_content{
  position: absolute;
  max-width: 910px;
  width: 100%;
  height: 90%;
  opacity: 1;
  background-color: #fff;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);
  border-radius: 16px 16px 0 0;
  padding: 0 118px 70px 118px;
  overflow: scroll;

}
.close_modal_terms{
  position: absolute;
  top: 110px;
  left: 40px;
  z-index: 15;
  cursor: pointer;
}


@media (max-width: 600px){
  .modal_terms_content{
    padding: 0;
    width: 85%;
    transform: translate(-50%, -3%);
    border-radius: 16px;
  }

  .close_modal_terms{
    top: 42px;
    left: 4px;
  }

  .modal_terms_wrapper{
    margin-top: 15px;
    .terms_content{
      h2{
        font-size: 16px;
        padding: 0 15px;
      }
    }
  }
}