@import "src/assets/styles/variable.scss";

.subscriptions {
  max-width: 1145px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .additional_info {
    display: none;
  }

  .primary_button_orange {
    max-width: 440px;
    width: 100%;
  }

  .footer-rec {
    display: none;
  }

  .footer_main {
    width: 100%;

    .footer_center {
      max-width: 340px;
      width: 100%;
    }
  }


  @media (max-width: 800px) {
    height: auto;
  }
}

.subscription_main {
  max-width: 1145px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscription_firstBlock {
  max-width: 735px;
  margin-bottom: 60px;

  @media (max-width: 800px) {
    margin-top: 35px;
    margin-bottom: 30px !important;
  }
}

.button-wrapper-subscriptionscreen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 32vw;
    max-width: 454px;
  }

  @media (max-width: 800px) {
    button {
      width: 100%;
      padding: 16px 0;
    }
  }
}

.subscription_firstBlock_h1 {
  margin: 26px 0 12px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 30px;
  //font-family: 'GilroyBold', serif;
  color: #383942;
  text-align: center;
}

.subscription_firstBlock_name {
  @extend .subscription_firstBlock_h1;
  margin: 0;
  line-height: 30px;

  &:first-child {
    margin-bottom: 10px;
    margin-top: 40px;
  }
}

.subscription_firstBlock_oneMonth {
  @extend .subscription_firstBlock_h1;
  line-height: 20px;
}

.subscription_promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  width: 220px;
  height: 220px;
  right: -200px;
  bottom: 45px;
  background-color: #E35F5F;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  border-radius: 50%;
  z-index: 10;
  pointer-events: none;

  @media (max-width: 800px) {
    display: none;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    right: -20%;
    bottom: 70%;
    width: 150px;
    height: 150px;
    border: 5px solid #E35F5F;
    border-radius: 50%;
    background-color: #fff;
    font-size: 13px;
    line-height: 133%;
    font-weight: 400;
    color: #383943;
    transform: rotate(12deg);

    & span {
      max-width: 100px;
    }
  }

  &Mobile {
    @extend .subscription_promo;
    position: relative;
    display: none;

    @media (max-width: 800px) {
      display: flex;
      top: 0;
      left: 0;
      margin-top: 50px;
    }
  }
}

.subscription_firstBlock_ul {
  max-width: 640px;
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 auto;
}

.subscription_firstBlock_ul li {
  margin-right: 10px;
}

.subscription_secondBlock {
  display: flex;
  flex-direction: column;
  margin: 24px 0 24px 0;
  align-items: center;

}

.subscription_main {
  .subscription_secondBlock {
    max-width: 564px;
    width: 100%;

    .currentBig, .subscription_itemBig {
      max-width: 564px;
      width: 100%;
    }
  }
}

.subscription-bottom-text {
  max-width: 472px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  font-family: GilroyRegular, sans-serif;
  color: #383942;
  font-size: 15px;
  line-height: 15px;

  * {
    width: 100%;
    min-height: 48px;
  }

  @media (max-width: 800px) {
    max-width: 500px;
    font-size: 12px;
    *{
      max-width: 311px;
      margin: 0 auto;
    }
  }
}

.subscription_item {
  position: relative;
  max-width: 448px;
  width: 100%;
  background: $white;
  border: 2px solid #006C61;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);

  .subscription_offer {
    position: absolute;
    left: 24px;
    top: -17px;
    padding: 6px 20px;
    background-color: #E35F5F;
    color: #FFFFFF;
    border-radius: 8px;

    &-green {
      @extend .subscription_offer;
      background-color: #E6F0EF;
      color: #006C61;
      font-size: 14px;
    }
  }

  &Big {
    @extend .subscription_item;
    //min-width: 34vw;
  }
}

.current {
  @extend .subscription_item;
  background-color: #006c61;
  color: #006c61;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);

  .subscription_option {
    p {
      color: #FFFFFF;
    }

    color: #FFFFFF;
  }

  &Big {
    @extend .current;
    //min-width: 34vw;
  }
}

.sub_container {
  display: flex;
}

.subscription_option {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    font-family: GilroyLight, sans-serif;
    color: #383943;
  }

  .subscription_description {
    font-family: GilroyLight, sans-serif;
    font-size: 13px;
    line-height: 19px;
  }

  .new_price {
    font-weight: bold;
    font-size: 31px;
    line-height: 37px;
    margin: 10px 0;
    display: inline-block;
    width: 100%;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;

    }
  }

  .round_monthly {
    position: relative;

  }

  .round_monthly label {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 28px;
    right: 0;
    position: absolute;
    bottom: 0;
    width: 28px;
  }

  .round_monthly label:after {
    border: 4px solid #29BF2F;
    border-top: none;
    border-right: none;
    content: "";
    height: 7px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 13px;
  }

  .round_monthly input[type="checkbox"] {
    visibility: hidden;
  }

  .round_monthly input[type="checkbox"]:checked + label {
    background-color: #fff;
  }

  .round_monthly input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}

.best_price_sub {
  position: absolute;
  top: -15px;
  left: 24px;
  font-size: 12px;
  padding: 7px 20px;
  color: $white;
  border-radius: 8px;
  background-color: $primary_one;
  text-transform: uppercase;
}

.subscription_description {
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
}


.subscription_button_wrapper {
  text-align: center;
  margin-bottom: 28px;

  button {
    width: 450px;
  }
}

.subscription_backLink {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  font-size: 16px;
  font-weight: 400;
  color: $primary_one;
  cursor: pointer;
}

@media (max-width: 600px) {
  .subscription_firstBlock_name:first-child{
    margin-top: 0;
  }
  .subscription_secondBlock{
    margin-top: 0;
  }
  .subscription_option .subscription_description{
    font-size: 12px;
  }
  .subscription_option p{
    font-size: 12px;
  }
  .subscription_firstBlock_h1, .subscription_firstBlock_oneMonth, .subscription_firstBlock_name{
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  .subscription_promo {
    display: none;
  }
  .subscription_main {
    max-width: 100%;
    padding: 0 10px 10px 10px;
  }
  .current,
  .subscription_item {
    max-width: 100%;
  }
  .subscription_backLink {
    margin-bottom: 55px;
  }
  .subscriptions {
    justify-content: flex-start;

    .footer_main {
      display: none;
    }

    .additional_info {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 32px;

      &-title {
        font-family: GilroySemi, sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #383942;
        margin-bottom: 10px;
      }

      &-text {
        font-family: GilroySemi, sans-serif;
        font-size: 16px;
        color: #383943;
        line-height: 21px;
        padding-left: 30px;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 15px;
          height: 15px;
          background: url("../../../src/assets/static/svg/sub_tick.svg") no-repeat;
          background-size: contain;
          left: 2px;
        }
      }
    }
  }
}
