@import "src/assets/styles/variable.scss";

.home {
  padding: 0 20px;
  height: 100vh;

  .subscription_offer-green {
    display: none;
  }

  .loader_icon {
    display: block;
    margin: auto;
  }

  h2 {
    font-family: 'GilroyBold', serif;
  }

  @media (max-width: 600px) {
    overflow-x: hidden;
  }
}

.mobile_view_images {
  display: none;
}

.home_wrapper {
  position: relative;
  overflow: hidden;
}

.landingLogo {
  margin: 0 auto;
  padding: 20px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.header_content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1376px;
  width: 100%;
  padding: 80px 116px 0 116px;
  background-color: #F3F8F7;
  border-radius: 15px;
}

.header-mobile {
  height: 100vh;
  overflow: auto;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 35% 20px 30% 20px;
  z-index: 20;

  button {
    text-transform: capitalize;
  }
}

.header_dropDown-links {
  display: flex;
  flex-direction: column;
  //margin-top: 130px;
}

.header_dropDown-link {
  color: #383942;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 46px;
}

.personalise_item-title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
}

.header_dropDown-button {
  max-width: 345px;
  width: 100%;
  padding: 15px 30px;
  text-transform: uppercase;
  border-radius: 8px;
  background-color: #006c61;
  font-size: 14px;
  line-height: 14px;
  outline: none;
  cursor: pointer;
  border: none;
  color: #fff;

}

.hideMenu {
  display: none;
}

.lock-scroll {
  overflow-y: hidden;
}

.active {
  position: fixed;
}

.header_content_main_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  img {
    max-width: 272px;
    width: 100%;
  }
}

.section_one_content_right {
  max-width: 585px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.section_one_content_right_top {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  img:first-child {
    align-self: center;
    margin-bottom: 15px;
    //transform: translate(-15px, 120px);
  }
}

.section_one_content_right_bottom {
  display: flex;

  .section_one_content_right_bottom_1st_img {
    max-width: 360px;
    width: 100%;
  }

  img:last-child {
    align-self: flex-end;
    margin-bottom: 47px;
    max-width: 216px;
    width: 100%;
    margin-left: 33px;
  }

  .mobile_img {
    display: none;
  }
}

.desktop_container {
  display: flex;
  flex-direction: column;
}

.section_one_content_left {
  max-width: 506px;
  width: 100%;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  height: 100%;


  button {
    max-width: 216px;
    width: 100%;
    font-size: 16px;
  }

  .description_second_section_one {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    max-width: 447px;
    width: 100%;
    color: #383943;
    font-family: GilroyLight, sans-serif;
    margin-top: 24px;
    margin-bottom: 40px;
    letter-spacing: .1px;

    .checkbox_info_right, .checkbox_info_left {
      font-size: 13px;
      line-height: 19px;
      font-family: GilroyLight, sans-serif;
    }

  }

  .description_section_one {
    font-family: GilroySemi, sans-serif;
    max-width: 640px;
    width: 100%;
    font-size: 20px;
    line-height: 26.6px;
    font-weight: 600;
    color: #383943;
    margin-bottom: 50px;
  }

  .title_section_one {
    max-width: 506px;
    width: 100%;
    font-weight: 700;
    font-family: GilroyBold, sans-serif;
    font-size: 39px;
    line-height: 46px;
    color: #383943;
    margin-bottom: 32px;
  }
}

/*END OF HEADER*/
//section second
.container_second_screen {
  max-width: 902px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0;

  img {
    max-width: 216px;
    width: 100%;
  }

  .section_second_right {
    max-width: 60%;

    h2 {
      font-weight: bold;
      font-size: 39px;
      line-height: 46px;
      margin-bottom: 16px;
      font-family: GilroyBold, sans-serif;
    }

    p {
      font-family: GilroyLight, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}


//end section second
/*section three*/
.section_three_wrapper {
  margin: 0 auto;
  max-width: 1380px;
  width: 100%;
  padding: 80px 46px 80px 116px;
  background-color: #F3F8F7;
  border-radius: 15px;

  h2 {
    font-family: GilroyBold, sans-serif;
    font-weight: 700;
    font-size: 39px;
    line-height: 46px;
    margin-bottom: 40px;
    max-width: 680px;
    letter-spacing: 1px;
    color: #383942;
  }
}

.section_three_wrapper_content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .section_three_wrapper_item {
    max-width: 27%;

    img {
      margin-bottom: 24px;
      max-width: 216px;
      width: 100%;
    }

    p {
      font-family: GilroyBold, sans-serif;
      color: #383943;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      max-width: 285px;
      width: 100%;
    }
  }
}

/* END section three */
//section stories
.mobile_slider {
  display: none;


  .slick-dots li button::before {
    font-size: 8px;
    color: #E6F0EF;
    opacity: 0.7;
  }

  .slick-dots li.slick-active button::before {
    color: #4D9890;
    opacity: 1;
  }
}

.section_stories {
  padding: 80px 0 48px;

  .container {
    max-width: 1144px;
    width: 100%;
    padding: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 39px;
    line-height: 46px;
    margin-bottom: 44px;
    font-family: GilroyBold, sans-serif;
    color: #383942;
  }

  .section_stories_content_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: #383943;

    .story_1 {
      background-color: #FCF0E8 !important;

      &::before, &:after {
        background-color: #F3CBAF !important;
      }
    }

    .story_2 {
      background-color: #F1F8E6 !important;

      &::before, &:after {
        background-color: #CFE6A9 !important;
      }
    }

    .story_3 {
      background-color: #EFF6FD !important;

      &::before, &:after {
        background-color: #C6E0F7 !important;
      }
    }

    .story_4 {
      background-color: #FDF7E0 !important;

      &::before, &:after {
        background-color: #F7E294 !important;
      }
    }
  }

  .section_stories_content_item1 {
    .section_stories_content_item_left {
      background-color: #F1F8E6;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 65px;
        height: 65px;
        background-color: #CFE6A9 !important;
        top: 68%;
        left: 10%;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #CFE6A9 !important;
        left: 65%;
        bottom: 10%;
        z-index: 22;
        border-radius: 50%;
      }
    }
  }

  .section_stories_content_item2 {
    .section_stories_content_item_left {
      background-color: #EFF6FD;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 65px;
        height: 65px;
        background-color: #C6E0F7 !important;
        top: 68%;
        left: 10%;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #C6E0F7 !important;
        left: 65%;
        bottom: 10%;
        z-index: 22;
        border-radius: 50%;
      }
    }
  }

  .section_stories_content_item3 {
    .section_stories_content_item_left {
      background-color: #FDF7E0;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 65px;
        height: 65px;
        background-color: #F7E294 !important;
        top: 68%;
        left: 10%;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #F7E294 !important;
        left: 65%;
        bottom: 10%;
        z-index: 22;
        border-radius: 50%;
      }
    }
  }

  .section_stories_content_item, .section_stories_content_item1, .section_stories_content_item2, .section_stories_content_item3 {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    max-width: 564px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 32px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);

    .section_stories_content_item_left {
      max-width: 211px;
      width: 100%;
      padding: 24px 38px;
      border-radius: 10px 0 0 10px;
      background-color: #FCF0E8;
      text-align: center;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 65px;
        height: 65px;
        background-color: #F3CBAF;
        top: 68%;
        left: 10%;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #F3CBAF;
        left: 65%;
        bottom: 10%;
        z-index: 22;
        border-radius: 50%;
      }

      img {
        position: relative;
        z-index: 21;
        max-width: 111px;
        width: 100%;
      }

      h3 {
        margin-bottom: 22px;
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
      }
    }

    .section_stories_content_item_right {
      padding: 50px 32px 50px 23px;
      border-radius: 0 10px 10px 0;
      text-align: left;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        font-family: GilroyLight, sans-serif;
        line-height: 21px;
        display: block;
        max-width: 298px;
        width: 100%;
      }
    }
  }
}


//end section stories
/* SUPPLEMETS old section three */
.supplements {
  margin: 0 auto;
  max-width: 1380px;
  width: 100%;
  background-color: #F3F8F7;
  padding: 80px 116px;
  border-radius: 20px;

}

.supplements_content {
  display: flex;
  justify-content: space-between;


  .section_three_button_wrapper {
    max-width: 217px;
    width: 100%;

    .primary_button_orange {
      width: 100%;
      font-size: 16px;
    }
  }


}

.supplements_content-title {
  font-weight: bold;
  font-size: 39px;
  line-height: 46px;
  margin-bottom: 32px;
  letter-spacing: .5px;
  color: #383943;
  max-width: 564px;
  width: 100%;
  font-family: GilroyLight, sans-serif;
}

.supplements_content-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  color: #383942;
  font-family: GilroyLight, sans-serif;
  max-width: 545px;
  width: 100%;
  letter-spacing: 0.1px;
}

.supplements_content-info {
  max-width: 50%;
}

/* END OF SUPPLEMETS */
/* NUTRIOTION */
.nutrition {
  padding: 80px 114px 80px;

  .container {
    max-width: 1132px;
    width: 100%;
    padding: 0;
  }
}

.nutrition-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.nutrition-title-text {
  font-family: GilroyBold, sans-serif;
  font-weight: 700;
  font-size: 39px;
  line-height: 46px;
  max-width: 564px;
  color: #383943;
  width: 100%;
}

.nutrition-title-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 412px;
  width: 100%;
  margin-right: 32px;
}

.nutrition-button_google,
.nutrition-button_app {
  max-width: 180px;
  width: 100%;
  min-height: 52px;
}


.nutrition-items {
  display: flex;
  justify-content: space-between;
}

.nutrition-item {
  max-width: 360px;
  width: 100%;
}

.nutrition-item_logo {
  margin: 25px 0 16px 0;
  display: flex;
  align-items: center;
}

.nutrition-item_logoName {
  margin-left: 13px;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
  font-family: GilroyBold, sans-serif;
}

.nutrition-item_logoName > span {
  font-family: Gilroy, sans-serif;
  font-weight: 400;
}

.nutrition-item_iconContainer1, .nutrition-item_iconContainer2, .nutrition-item_iconContainer3 {
  width: 42px;
  height: 42px;
  background-color: #E0A019;
  border-radius: 50%;

}

.nutrition-item_iconContainer2 {
  background-color: #D56663;
}

.nutrition-item_iconContainer3 {
  background-color: #78BDB4;
}

.nutrition-item_img1, .nutrition-item_img2, .nutrition-item_img3 {
  width: 20px;
  height: 20px;
  transform: translate(52%, 54%);
}

.nutrition-item_img {
  max-width: 328px;
  width: 100%;
}

.nutrition-item_icon {

}

.nutrition-item_text {
  max-width: 332px;
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  font-family: Gilroy, sans-serif;
  color: #383942;
}

/* END OF NUTRIOTION */
/* DISCOVER section five*/
.discover {
  margin: 0 auto;
  max-width: 1380px;
  width: 100%;
  padding: 80px 116px;
  background-color: #F3F8F7;
  border-radius: 16px;
}

.discover_leftcontent_wrapper h2 {
  width: 100%;
  font-family: GilroyBold, sans-serif;
  color: #383943;
}

.discover-container {
  display: flex;
  justify-content: space-between;
}

.discover-content {
  display: flex;
  flex-direction: column;
  max-width: 597px;
  justify-content: space-between;
  width: 100%;

  .primary_button_orange {
    max-width: 216px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }
}

.discover-content_title {
  font-weight: bold;
  font-size: 39px;
  line-height: 46px;
  margin-bottom: 32px;
  font-family: GilroySemi, sans-serif;
}

.discover-content_text {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 78px;
  color: #383943;
  max-width: 560px;
  width: 100%;
}

.discover-img {
  margin-left: 50px;

  img {
    max-width: 448px;
    width: 100%;
  }
}

.section_five_img_wrapper {
  max-width: 500px;
  width: 100%;
  padding: 35px 47px;
  background-color: #B3D3D0;
  border-radius: 10px;
  margin-bottom: 40px;
}

//#discover-video {
//  max-width: 500px;
//  height: 166px;
//}

/* END OF DISCOVER */
/* APPLICATION section six*/
.header_recommendation {
  position: relative;
  max-width: 910px;
  width: 100%;
  margin: 80px auto 97px;
  display: flex;
  max-height: 295px;
  color: #fff;

  &:after {
    position: absolute;
    content: url("../../assets/static/homePageImages/images/headerImages/rec-img2.png");
    right: 65%;
    bottom: -15%;
    z-index: 22;
  }
}

.mobile_reply {
  display: none;
}

.header_recommendation::before {
  position: absolute;
  content: "";
  //background-image: url('/./src/assets/static/homePageImages/images/headerImages/rec-img2.png');
  //background-image: url("./images/headerImages/rec-img2.png");
  background-repeat: no-repeat;
  width: 144px;
  height: 148px;
  left: 175px;
  top: 186px;
}

.header_recommendation-img {
  border-radius: 20px 0 0 20px;
}

.header_recommendation-content {
  border-radius: 0 20px 20px 0;
  background-color: #4D9890;
  display: flex;
  color: $white;
  flex-direction: column;
  padding: 52px 47px 69px 36px;
}

.recommendation-title {
  font-family: GilroySemi, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  position: relative;
  color: $white;
  padding-right: 20px;
  letter-spacing: .5px;
}

.recommendation-title::after {
  position: absolute;
  content: url("../../assets/static/homePageImages/images/headerImages/check.svg");
  width: 24px;
  height: 24px;
  bottom: -2px;
  margin-left: 8px;
}

.recommendation-text {
  margin-top: 18px;
  font-size: 22px;
  font-style: italic;
  line-height: 33px;
  color: $white;
  max-width: 533px;
  width: 100%;
}

/* END OF APPLICATION */
//last section
.last_section {
  margin: 0 auto;
  max-width: 1380px;
  width: 100%;
  padding: 80px 116px;
  background-color: #F3F8F7;
  border-radius: 15px;

  .primary_button_orange {
    max-width: 216px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }

  h1 {
    font-family: GilroyBold, sans-serif;
    color: #383943;
    font-weight: bold;
    font-size: 39px;
    line-height: 46px;
    margin-bottom: 32px;
  }

  .last_section_check_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;

    p {
      font-family: GilroySemi, sans-serif;
      width: 49%;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
      position: relative;
      padding-left: 20px;
      color: #383943;

      &:after {
        position: absolute;
        content: url("../../assets/static/homePageImages/updateIMG/Tick.png");
        left: 0;
        width: 15px;
      }
    }
  }

  .last_section_content {
    .description_second_section_one {
      display: block;
      margin-bottom: 40px;
      font-weight: normal;
      font-size: 13px;
      font-family: GilroyLight, sans-serif;
      line-height: 19px;
      color: #383943;
    }

    .primary_button_orange {
      max-width: 216px;
      width: 100%;
    }
  }

  .checkbox_info_left {
    margin: 0 auto 0 10px;
    max-width: 440px;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    font-family: GilroyLight, sans-serif;
    line-height: 19px;
    color: #383943;
  }

  .checkbox_info_right {
    margin: 0 120px 0 auto;
    max-width: 440px;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    font-family: GilroyLight, sans-serif;
    line-height: 19px;
    color: #383943;
  }

  .checkbox_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;


    .subscription_item {
      max-width: 567px;
    }

    .current {
      .subscription_option {
        p {
          color: #FFFFFF;
          font-family: GilroySemi, sans-serif;
          font-size: 13px;
          line-height: 17px;
        }

        p:last-child {
          font-size: 13px;
          line-height: 19px;
          font-family: GilroyLight, sans-serif;
        }

        color: #FFFFFF;
      }

      max-width: 564px;
    }
  }
}

//end last section
@media (max-width: 600px) {
  .home_wrapper {
    padding: 0 16px;

    .header_main {
      padding: 20px 0;
    }
  }
  .last_section {
    padding: 40px 20px;

    .last_section_check_wrapper {
      p {
        width: 100%;
        padding-left: 30px;
        font-size: 16px;
        line-height: 21px;

        &:after {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .last_section_content {
      .primary_button_orange {
        display: block;
        margin: 0 auto;
      }

      .description_second_section_one {
        max-width: 100%;
      }
    }

    .checkbox_wrapper {
      flex-direction: column;

      .current {
        .subscription_option {
          p {
            color: #FFFFFF;
          }

          color: #FFFFFF;
        }

        max-width: 567px;
      }
    }

    .primary_button_orange {
      width: 100%;
    }
  }
  .desktop_content {
    display: none;
  }
  .mobile_slider {
    display: block;
  }
  .section_stories {
    padding: 32px 0 40px;

    .slider_header_title {
      margin-bottom: 14px;
      margin-left: 9px;
      font-size: 31px;
      line-height: 36px;
    }

    .section_stories_content_item, .section_stories_content_item1, .section_stories_content_item2, .section_stories_content_item3 {


      max-width: 97%;
      margin: 10px 8px;

      .section_stories_content_item_left {
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        max-width: 100%;
        padding: 24px 20px;

        &::before {
          top: 68%;
          left: 29%;
        }

        &::after {
          left: 58%;
          bottom: 10%;
        }

        img {
          max-width: 115px;
          width: 100%;
        }
      }

      .section_stories_content_item_right {
        padding: 40px 20px;
        border-radius: 0 0 10px 10px;
        text-align: left;
      }
    }
  }
  .personalise_item-images_icon {
    display: none;
  }
  .supplements {
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
    background-color: #F3F8F7;
    padding: 80px 116px;
    border-radius: 20px;
  }
  .supplements_content-text {
    margin-bottom: 24px;
  }

  .supplements_content {
    flex-direction: column-reverse;

    .section_three_button_wrapper {
      margin: 0 auto;
    }
  }

  .supplements_content-title {
    font-weight: bold;
    font-size: 39px;
    line-height: 46px;
    margin-bottom: 16px;
  }
  .supplements_content-img {
    margin-bottom: 25px;
    display: inline-block;

  }
  .supplements_content-info {
    max-width: 100%;
  }
  .mobile_reply {
    display: block;
    max-width: 290px;
    width: 100%;
    margin: 0 auto 10px;

  }
  .section_three_wrapper {
    padding: 32px 8px;

    h2 {
      font-weight: bold;
      font-size: 31px;
      line-height: 37px;
      max-width: 100%;
    }
  }
  .section_three_wrapper_content {
    flex-direction: column;
    align-items: center;

    .section_three_wrapper_item {
      max-width: 100%;
      text-align: center;
      margin-bottom: 32px;

      p {
        max-width: 297px;
        text-align: start;
      }

      img {
        margin-bottom: 18px;
      }
    }
  }

  .container_second_screen {
    flex-direction: column;
    padding: 30px 20px;

    img {
      max-width: 128px;
      width: 100%;
    }

    .section_second_left {
      margin-bottom: 16px;
    }

    .section_second_right {
      max-width: 100%;


      h2 {
        font-weight: bold;
        font-size: 31px;
        line-height: 37px;
      }
    }
  }
  .header_content_main_wrapper {
    flex-direction: column;
  }
  .section_one_content_right {
    max-width: 100%;
    margin-bottom: 32px;
    display: none;
  }
  .mobile_view_images {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 32px;

    img:first-child {
      max-width: 180px;
      width: 100%;
    }

    .mobile_view_images_group {
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      max-width: 140px;
      width: 100%;

      img:first-child {
        max-width: 136px;
        width: 100%;
        align-self: flex-end;
      }

      img:last-child {
        max-width: 117px;
        width: 100%;
        align-self: flex-end;
        margin-top: 16px;

      }
    }
  }
  .section_one_content_left {
    max-width: 100%;
    padding-bottom: 20px;

    button {
      margin: 0 auto;
    }


    .description_second_section_one {
      max-width: 100%;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      margin: 18px 0 24px 0;
    }

    .description_section_one {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 10px;
    }

    .title_section_one {
      width: 100%;
      font-weight: bold;
      font-size: 25px;
      line-height: 29px;
      margin-bottom: 16px;
    }
  }
  .section_one_content_right_top {
    display: none;
  }
  .header-menu_icon {
    display: block;
    margin-left: 10px;
  }

  .header-nav {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }
  .header-nav.active {
    margin: 0 10px;
  }
  .header_text {
    display: none;
  }

  .header-button {
    display: none;
  }

  .header_user {
    display: none;
  }

  .header_content {
    width: 100%;
    flex-wrap: wrap;
    padding: 32px 8px;

    .current, .subscription_item {
      max-width: 311px;
      margin: 32px auto 0;
      width: 100%;

      .subscription_option p {
        font-size: 10px;
        line-height: 15px;
        font-family: GilroyLight, sans-serif;

        .subscription_description {
          font-size: 10px;
          line-height: 15px;
          font-family: GilroyLight, sans-serif;
        }
      }
    }
  }
  .home {
    padding: 0;
    width: 100%;
  }
  .header_content-info {
    padding: 0;
  }

  .header_content-title {
    font-size: 30px;
    line-height: 36px;
  }

  .header_content-text {
    font-size: 16px;
    line-height: 24px;
  }
  .discover-img {
    display: none;
  }
  .header_content-button {
    width: 100%;
    padding: 19px 78px;
    font-size: 14px;
    line-height: 17px;
  }
  .header_content_info_button_wrapper {
    button {
      width: 100%;
    }
  }
  .header_content-image {
    max-width: 100%;
    width: 100%;

    #header_video {
      margin-top: 40px;
      max-width: 100%;
      width: 120%;
    }
  }

  .header_recommendation {
    margin: 30px auto;
    max-height: 100%;
    flex-direction: column;

    .header_recommendation-img {
      border-radius: 20px 20px 0 0;
      max-width: 100%;
      width: 100%;

    }

    &:after {
      right: 0;
      bottom: 27%;
      z-index: 22;
    }
  }

  .header_recommendation-content {
    border-radius: 0 0 16px 16px;
    padding: 37px 20px 30px 20px;
    margin-bottom: 20px;
  }

  .recommendation-text {
    font-size: 16px;
    line-height: 18px;
  }

  .recommendation-title {
    font-size: 16px;
    line-height: 18px;

    &:after {
      position: absolute;
      content: url("../../assets/static/homePageImages/images/headerImages/check.svg");
      width: 24px;
      height: 24px;
      bottom: -5px;
      margin-left: 8px;
    }
  }

  .header_recommendation::before {
    left: 216px;
    top: 218px;
  }

  .container {
    width: 100%;
    padding: 60px 30px;
  }

  .personalise {
    padding: 0;
  }

  .personalise-title {
    padding-top: 60px;
    font-size: 30px;
    line-height: 36px;
  }

  .personalise_items {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .personalise_item2,
  .personalise_item {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
  }

  .personalise_item-title {
    font-size: 22px;
  }


  .program {
    padding: 0;
  }

  .program_content {
    margin-bottom: 40px;
    max-width: 100%;

    div {
      margin-bottom: 0;

      button {
        width: 100%;
      }
    }
  }

  .program_container {
    flex-wrap: wrap;
    padding: 60px 0;
  }

  .program_content-title p {
    font-size: 30px;
  }

  .program_content_text {
    font-size: 24px;
    font-weight: 700;
  }

  .program_content-button {
    margin: 20px auto 40px;
  }
  .program_cycle {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }
  .program_cycle-overview {
    max-width: 222px;
    height: 200px;
  }

  .purple-small_ball {
    left: -35px;
    top: 132px;
    width: 8px;
    height: 8px;
  }

  .blue-small_large {
    width: 20px;
    height: 20px;
  }

  .purple-large_ball {
    width: 20px;
    height: 20px;
    left: 100px;
    top: -26px;
  }

  .blue-small_ball {
    width: 10px;
    height: 10px;
    top: -9px;
    bottom: 0;
    right: 44px;
  }

  .green-large_ball {
    height: 20px;
    width: 20px;
    right: 15px;
    bottom: -4px;
  }

  .overview_item {
    width: 167px;
    height: 167px;
    left: -54px;

  }

  .overview_item2 {
    width: 160px;
    height: 160px;
    top: 10px;
    left: 128px;
    padding-top: 0;
  }

  .overview_item3 {
    width: 168px;
    height: 168px;
    bottom: -94px;
    left: -1px;
  }

  .overview_item-icon {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .overview_item-title {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .program_cycle-description {
    color: #606168;
    font-size: 14px;
  }

  .supplements {
    padding: 30px 8px;
    max-width: 100%;
  }

  .supplements_content {
    flex-wrap: wrap;
  }

  .supplements_content-title {
    font-size: 28px;
    line-height: 36px;
  }

  .supplements_content-text {
    font-size: 16px;
    line-height: 24px;
  }

  .supplements_content-img {
    max-width: 100%;
  }

  .supplements-needs-title {
    font-size: 22px;
    line-height: 26px;
  }

  .supplements-needs_items {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .supplements-needs-item {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 25px;
  }
  .section_three_button_wrapper {
    button {
      width: 100%;
      max-width: 216px;
    }
  }
  .supplemets-needs-text {
    font-size: 18px;
    line-height: 27px;
  }

  .nutrition {
    padding: 32px 0 0;
    margin-top: 0;
  }

  .nutrition-title {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .nutrition-item_logo {
    margin: 20px 0 16px 0;
  }
  .nutrition-title-text {
    margin-bottom: 20px;
    font-size: 31px;
    line-height: 36px;
  }

  .nutrition-title-buttons {
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .nutrition-button_app {
      margin: 0;
    }
  }

  .nutrition-items {
    flex-wrap: wrap;
  }
  .nutrition-title-buttons {
    margin-right: 0;
  }
  .nutrition-item_img {
    max-width: 100%;
  }
  .nutrition-item {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .nutrition-item_img {
    width: 100%;
    height: auto;
  }

  .nutrition-item_text {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    padding: 0 12px;
  }

  .discover {
    padding: 30px 20px;
    max-width: 100%;
    width: 100%;
  }

  .discover-content_title {
    padding-top: 0;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .discover-container {
    flex-wrap: wrap;
  }

  #discover-video {
    max-width: 315px;
    height: 81px;
  }

  .discover-content_button {
    margin-bottom: 20px;
    margin-top: 0;
    line-height: 17px;
  }
  .section_five_img_wrapper {
    padding: 4px 32px;
  }
  .discover-content {
    .primary_button_orange {
      display: block;
      margin: 0 auto;
    }

    .section_five_img_wrapper {
      display: none;
      max-width: 252px;
      width: 100%;
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }

    .discover-content_text {
      margin-bottom: 0;
    }

    .discover_leftcontent_wrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    div {
      margin-bottom: 25px;

      button {
        width: 100%;
      }
    }
  }
  .discover-img img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transform: translateX(8px);
  }

  .application {
    max-width: 100%;
    margin: 30px auto 0;
    width: 100%;
    padding: 0;
  }

  .application-container {
    padding: 30px 20px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }


  .app-sticker {
    max-width: 100%;
    height: 100%;
  }

  .app-book {
    max-width: 100%;
    height: auto;
  }

  .app-circle-icon {
    width: 43px;
    height: 43px;
  }

  .app-circle_reply {
    width: 89px;
    padding: 22px 20px 15px 10px;
  }

  .app-sticker_phone {
    max-width: 80%;
    height: auto;
  }

  .application-rightSide_item2 {
    transform: translateX(-27px);
    margin-left: 25px;
  }

  .application-rightSide_item1 {
    padding-top: 19px;
    justify-content: space-between;
  }

  .application-content {
    padding-top: 30px;

    div {
      margin-top: 20px;

      button {
        width: 100%;
      }
    }
  }
  .slider_title {
    max-width: 300px;
    width: 100%;
    display: block;
  }
  .checkbox_info_left, .checkbox_info_right {
    min-height: 75px;
  }
  .last_section {
    .checkbox_info_left, .checkbox_info_right {
      max-width: 440px;
      width: 100%;
      font-weight: normal;
      font-size: 13px;
      font-family: GilroyLight, sans-serif;
      line-height: 19px;
      color: #383943;
      min-height: 80px;
      margin: 0 auto 0 10px;
    }
  }

  .application-content_title {
    font-size: 30px;
    line-height: 36px;
  }

  .application-content_button {
    max-width: 315px;
    width: 100%;
  }

  .footer {
    max-width: 355px;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    border-top: none;
    margin: 0 auto;
  }

  .footer-text {
    flex-direction: column;
    margin-left: 0;
    align-items: center;
  }

  .footer-text a {
    margin-bottom: 30px;
  }

  .footer a {
    margin-bottom: 30px;
  }
}
