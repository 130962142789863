@import "../../../assets/styles/variable";
.modal {
  overflow: scroll;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background-color: $black;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  .loader_icon{
    margin: 0 auto;
  }
}
.modal_body {
  background-color: $white;
  padding: 20px 20px;
  border-radius: 16px;
  margin: auto;
  position: relative;
  z-index: 70;
}
.close_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .modal_head_title {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: $black;
    margin-right: 210px;
  }
  .icon_wrap {
    cursor: pointer;
    background-color: $secondary_one_active;
    display: inline-block;
    margin-left: auto;
    line-height: 0;
    padding: 10px 10px;
    border-radius: 8px;
  }
}
.modal_content {
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .primary_button_green.end_sub_btn{
    max-width: 86px;

  }
  h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin:  0 0 16px 0;
    color: $black;
    max-width: 400px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $color;
  }
  Button {
    margin: 15px 12px 0 0 ;

  }
  .best_price_sub{
    top: -13px;
    right: -2px;
  }
  .subscription_item{
    max-width: 100%;
  }
}

.order_account_top_wrapper_right_order_list {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  margin-bottom: 24px;

  li {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-right: 12px;
    margin-bottom: 8px;
  }
}

.current_subscription {
  padding: 30px 30px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  .choose_subscription_left {
    span {
      display: inline-block;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 10px;
      color: $account_side_menu;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      color: $black;
    }
  }

  .choose_subscription_right {
    text-align: right;

    div {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $white;
      border-radius: 24px;
      padding: 4px 8px;
      background-color: $secondary_two;
      margin-bottom: 6px;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: $black;
    }
  }
}

.choose_subscription {
  margin-bottom: 24px;
  border: 2px solid rgba(196, 196, 196, 0.3);
  padding: 30px 30px;
  border-radius: 16px;
  position: relative;

  .best_value_item {
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    border-radius: 24px;
    padding: 6px 8px;
    background-color: $secondary_two;
    color: $white;
    top: -15px;
    right: 0;
  }
}

.active_monthly {
  border: 2px solid $button_order_account;
}

.other_options_title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 24px 0;
}
.loader_modal-Wrapper {
  text-align: center;
  width: 100%;
  padding: 15px 0;
  img {
    max-width: 40px;

  }
}
.modal_buttons_wrapper{
  display: flex;
  gap: 10px;
 .primary_button_green{
   max-width: 175px;
   width: 100%;
 }

  .secondary_button_gray{
    max-width: 119px;
    width: 100%;
  }
}

.choose_subscription_second {
  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: $button_order_account;
    border-color: $button_order_account;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}

.choose_subscription {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .choose_subscription_checkbox {
    width: 10%;
    .round {
      position: relative;
    }
    .round label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 28px;
    }

    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 8px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 12px;
    }
    .round input[type="checkbox"] {
      visibility: hidden;
    }
    .round input[type="checkbox"]:checked + label {
      background-color: $button_order_account;
      border-color: $button_order_account;
    }
    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }

  //
  .choose_subscription_checkbox_monthly {
    width: 10%;

    .round_monthly {
      position: relative;
    }

    .round_monthly label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 29px;
    }

    .round_monthly label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 8px;
      top: 8px;
      opacity: 0;
      position: absolute;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round_monthly input[type="checkbox"] {
      visibility: hidden;
    }

    .round_monthly input[type="checkbox"]:checked + label {
      background-color: $button_order_account;
      border-color: $button_order_account;
    }

    .round_monthly input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }
  .choose_subscription_content {
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 8px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $color;
    }
  }
  .choose_subscription_sum {
    text-align: right;
    width: 20%;
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      &:last-child {
        color: $account_side_menu;
        margin-left: 8px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $account_side_menu;
          top: 35%;
          left: 0;
        }
      }
    }
  }
}

.order_subscription_sub_title {
  h4 {
    margin: 20px 0 8px 0;
  }
}

.end_subscription_title {
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
}

//.settings_modal_button_wrapper {
//  display: flex;
//  button {
//    font-size: 12px;
//  }
//}
@media (max-width: 600px) {
  .modal_body {
    max-width: 90%;
    margin: auto;
  }
  .close_button_wrapper {
    .modal_head_title {
      font-size: 22px;
      line-height: 25px;
      margin-right: 0;
    }

    .icon_wrap {
      line-height: 0;
      padding: 7px 7px;
    }
  }
  .modal_content {
    h3 {
      font-size: 22px;
      line-height: 25px;
      margin: 0 0 12px 0;
      max-width: 400px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $color;
    }

    Button {
      margin: 15px 12px 0 0;
    }

    .subscription_item {
      max-width: 100%;
    }

    .best_price_sub {
      top: -13px;
      right: -2px;
    }

  }
  //.order_account_top_wrapper_right_order_list {
  //  margin-bottom: 24px;
  //  li {
  //    font-weight: normal;
  //    font-size: 14px;
  //    line-height: 24px;
  //    margin-right: 12px;
  //    margin-bottom: 8px;
  //  }
  //}
  .current_subscription {
    padding: 15px 15px;
    margin-bottom: 12px;

    .choose_subscription_left {
      span {
        font-size: 12px;
        line-height: 15px;
      }

      p {
        font-size: 16px;
      }
    }

    .choose_subscription_right {
      text-align: right;

      div {
        font-size: 12px;
      }

      p {
        font-size: 18px;
        line-height: 25px;
        color: $black;
      }
    }
  }
  .choose_subscription {
    margin-bottom: 18px;
    padding: 15px 15px;

    .best_value_item {
      position: absolute;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .other_options_title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 12px 0;
  }
  .choose_subscription_second {
    .round label {
      height: 20px;
      width: 20px;
    }

    .round label:after {
      height: 3px;
      left: 4px;
      top: 5px;
      width: 8px;
    }
  }
  .choose_subscription {
    .choose_subscription_checkbox {
      width: 25%;

      .round label {
        height: 20px;
        width: 20px;
      }

      .round label:after {
        height: 3px;
        left: 4px;
        top: 5px;
        width: 8px;
      }
    }

    //
    .choose_subscription_checkbox_monthly {
      width: 25%;
      .round_monthly label {
        height: 20px;
        width: 20px;
      }
      .round_monthly label:after {
        height: 3px;
        left: 4px;
        top: 5px;
        width: 8px;
      }
    }
    .choose_subscription_content {
      h3 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 6px;
      }
      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .choose_subscription_sum {
      width: 20%;
      display: flex;
      flex-direction: column;
      span {
        font-size: 18px;
        line-height: 20px;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
  .order_subscription_sub_title {
    h4 {
      margin: 15px 0 8px 0;
    }
  }
  .end_subscription_title {
    font-size: 18px;
    line-height: 22px;
  }
  .settings_modal_button_wrapper {
    display: flex;
    button {
      font-size: 12px;
    }
  }
}
