@import "../../assets/styles/variable";

.connect_with_us{
  max-height: 100vh;
}

.connectWithUs_title {
 margin: 0 0 10px 0 !important;
}
.connectWithUs_text{
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #383942;
  line-height: 27px;
  margin: 5px 0 20px 0;
}
.content_form{
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.textarea::placeholder {
  font-size: 16px;
}
.textarea{
  height: 120px;
  max-width: 400px;
  width: 100%;
  background: #F5F5F5;
  border-radius: 8px;
  padding: 17px 12px 17px 16px;
  outline: none;
  resize: none;
  font-size: 16px;
  border: 1px solid $secondary_one;
  &:focus {
    border-color: $primary_one_active;
  }
}

.footer {
  width: 100%;
  padding: 28px 100px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #c4c4c430;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-text {
  display: flex;
  justify-content: space-between;
  max-width: 60%;
  width: 100%;
}

.terms,
.connect,
.footer-since {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #606168;

}

.footer-since {
  color: #c4c4c4;
  margin-right: 74px;
}
a{
  text-decoration: none;
}


@media (max-width: 600px) {
  .log_in_container h1{
    margin-top:12px
  }
  .container{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }
  .header{
    width: 100%;
    height: 72px;
  }
  .content h1{
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 30px;
  }
  .content p{
    font-size: 18px;
  }
  .content_form input{
    width: 100%;
    height: 56px;
    margin: 0 0 12px 0;
  }
  .textarea{
    width: 100%;
    height: 102px;
    margin:  0 0 20px 0;
  }
  .content_button{
    width: 100%;
    height: 56px;
    margin: 10px 0;
  }
  .footer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    border-top: none;
    padding: 0;
    position: static;
  }

  .footer-text {
    max-width: 300px;
    width: 100%;
    flex-direction: column;
    margin: 30px 0 0 0;
    align-items: center;
  }
  .footer img{
    margin-top: 60px;
    margin-bottom: 20px;
  }
.connect{
  margin: 34px 0 27px 0;
}
.footer-since{
  width: 100%;
  margin:0 0 30px 0;
}


}

