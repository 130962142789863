@import "../../../assets/styles/variable";
.recommendation_account {
  border-radius: 16px;
  border: 1px solid rgba(196, 196, 196, 0.3);
  padding: 32px 32px;
}
.recommendation_account_title {
  font-weight: bold;
  font-size: 28px;
  color: $black;
  margin: 0 0 32px 0;
  text-align: left;
}
.recommendation_item {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
  img {
    max-width: 200px;
    margin-right: 16px;
  }
}
.recommendation_item_body {
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: $black;
    margin: 0 0 16px 0;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $black;
    margin: 0 0 16px 0;
  }
}
.recommendation_more_button {
  color: $primary_one;
}
.recommendation_button_wrapper {
  text-align: right;
}
.side_menu_recommendation {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(56, 57, 66, 0.41);
  transition: all .5s ease;
}
.side_menu_recommendation_body {
  max-width: 50%;
  padding: 35px 35px;
  background-color: white;
  height: 100vh;
  margin-left: auto;
  img {
    cursor: pointer;
  }
}
.side_menu_header {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  img {
    max-width: 180px;
    margin-right: 33px;
  }
  div {

    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: $black;
      margin-bottom: 16px;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.side_menu_body {
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: $black;
  margin-bottom: 20px;
}
.side_menu_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .side_menu_item {
    padding-left: 34px;
    position: relative;
    line-height: 24px;
    &:before {
      border-radius: 24px;
      left: 0;
      top: 0;
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      background-color: #383942;
    }
  }
}
.side_menu_bottom {
  padding: 20px 20px;
  border-radius: 16px;
  background-color: rgba(104, 140, 210, 0.1);
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    margin: 0 0 10px 0;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 20px;
  }
  div {
    text-align: center;
    img {
      max-width: 400px;
    }
  }
}
@media (max-width: 600px) {
  .recommendation {
    padding: 15px 15px;
  }
  .recommendation_account {
    padding: 15px 15px;
  }
  .recommendation_account_title {
    font-size: 24px;
    margin: 0 0 25px 0;
  }
  .recommendation_item {
    flex-direction: column;
    margin-bottom: 40px;
    img {
      max-width: 170px;
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
  .recommendation_item_body {
    h3 {
      font-size: 18px;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $black;
      margin: 0 0 16px 0;
    }
  }
  .side_menu_recommendation {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 57, 66, 0.41);
    transition: all .5s ease;
  }
  .side_menu_recommendation_body {
    max-width: 100%;
    padding: 15px 15px;
    height: 100%;
    margin-left: auto;
    img {
      cursor: pointer;
    }
  }
  .side_menu_header {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    img {
      max-width: 180px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .side_menu_body {
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    color: $black;
    margin-bottom: 20px;
  }
  .side_menu_items {
    flex-wrap: wrap;
    .side_menu_item {
      padding-left: 34px;
      position: relative;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
  .side_menu_bottom {
    display: none;
  }
}
