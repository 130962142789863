@import "../../../assets/styles/variable";
.payment_account {
  border-radius: 16px;
  border: 1px solid rgba(196, 196, 196, 0.3);
  padding: 32px 32px;
}
.payment_account_title {
  font-weight: bold;
  font-size: 28px;
  color: $black;
  margin: 0 0 30px 0;
  text-align: left;
}
.payment_account_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 0;
}
.payment_account_item_data_wrapper {
  text-align: left;
}
.payment_account_item_card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.payment_account_item_action_primary {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  span {
    margin-right: 12px;
    font-weight: 600;
    font-size: 16px;
    color: $black;
  }
}
.payment_account_loader_wrapper {
  text-align: right;
  img {
    max-width: 30px;
  }
}
.payment_account_item_action_remove {
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  color: $black;
  position: relative;
  padding-right: 22px;
  cursor: pointer;
  &:after {
    position: absolute;
    content: url("../../../assets/static/icon/cart.svg");
    right: 0;
    top: 50%;
    transform: translateY(-40%);
  }
}
.payment_account_item_name {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: $black;
  line-height: 22px;
  margin-bottom: 6px;
}
.payment_account_item_card_number {
  font-weight: normal;
  font-size: 16px;
  color: $black;
  line-height: 22px;
  padding-right: 4px;
}
.payment_account_item_card_exp {
  font-weight: normal;
  font-size: 16px;
  color: $black;
  line-height: 22px;
}
.payment_account_item_method {
  margin-right: 16px;
}
.payment_account_item_data {
  display: flex;
  align-items: center;
}
.payment_add {
  color: $primary_one;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: all .5s ease;
  &:hover {
    color: $primary_one_hover;
  }
  &:active {
    color: $primary_one_active;
  }
}
.payment_input_wrapper {
  input {
    margin-bottom: 10px;
  }
}
.payment_method_wrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-right: 5px;
    cursor: pointer;
  }
}
.add_payment_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 12px;
  margin-top: 38px;
  color: $black;
  text-align: left;
}
@media (max-width: 600px) {
  .payment_account_item {
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 0;
  }
  .payment_account_item_card {
    align-items: flex-start;
  }
  .payment_account_item_data {
    margin-bottom: 8px;
  }
  .payment_account_item_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .payment_account_item_action_primary {
    margin-bottom: 0;
  }
  .payment_account {
    padding: 32px 20px;
  }
}
