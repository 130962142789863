@import "../../../assets/styles/variable";
.personal_details_account {
  border-radius: 16px;
  border: 1px solid rgba(196, 196, 196, 0.3);
  padding: 32px 32px;
}
.personal_details_account_title {
  font-weight: bold;
  font-size: 28px;
  color: $black;
  margin: 0;
  text-align: left;
}
.personal_details_account_item {
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
}
.personal_details_account_name {
  width: 100%;
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 16px 0;
    color: $black;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
  }
}
.personal_details_account_edit_button {
  color: $primary_one;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding-right: 24px;
  position: relative;
  transition: all .5s ease;
  &:hover {
    color: $primary_one_hover;
  }
  &:active {
    color: $primary_one_active;
  }
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../../../assets/static/icon/pen.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.last_item {
  border-bottom: none;
}
.personal_details_account_add_address {
  color: $primary_one;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: all .5s ease;
  &:hover {
    color: $primary_one_hover;
  }
  &:active {
    color: $primary_one_active;
  }
}
.name_input_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  div {
    width: 49%;
  }
  input {
    width: 100%;
  }
}
.error_phone_message {
  font-size: 12px;
  padding: 5px 5px;
  color: $invalid_border;
}
.invalid_input_phone {
  border: 1px solid
}
.name_input_wrapper_two {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
  div {
    width: 49%;
    input {
      width: 100%;
    }
  }
}
.name_button_wrapper {
  display: flex;
  button {
    margin-right: 10px;
  }
}
@media (max-width: 600px) {
  .personal_details_account_title {
    font-size: 24px;
  }
  .personal_details_account {
    padding: 32px 20px;
  }
}
