@import "../../assets/styles/variable";

.quiz {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: 100px auto min-content / 1fr;

  .login_header_button_back {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    position: absolute;
    left: 146px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $black;

    &:before {
      transform: rotate(180deg);
      position: absolute;
      content: url("../../assets/static/svg/Chevron-right.svg");
      left: 0;
      top: 0;
      height: 13px;
    }
  }

  footer {
    grid-row: 3;
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.square-bottom-left, .square-bottom-right {
  height: 235px;
  width: 245px;
  position: relative;
  overflow: hidden;
}

.full-circle-left {
  z-index: 2;
  height: 282px;
  width: 302px;
  background-color: #78BDB4;
  border-radius: 50%;
  position: absolute;
  left: -66.94%;
  right: 43.67%;
  top: 56.6%;
  bottom: -76.6%;
  opacity: 0.1;
}

.circle-left {
  z-index: 3;
  position: absolute;
  left: -72.65%;
  right: 49.39%;
  top: 49.36%;
  bottom: -74.04%;
  border: 3.05909px solid rgba(0, 108, 97, 0.3);
  border-radius: 50%;
  height: 293px;
  width: 302px;
}


.full-circle-right {
  height: 468.8px;
  width: 483.3px;
  z-index: 2;
  background-color: #78BDB4;
  border-radius: 50%;
  position: absolute;
  left: 15.51%;
  right: -113.16%;
  top: 0.85%;
  bottom: -93.06%;
  opacity: 0.1;
}

.circle-right {
  height: 468.8px;
  width: 483.3px;
  z-index: 3;
  position: absolute;
  left: 7.95%;
  right: -105.23%;
  top: 10.24%;
  bottom: -109.73%;
  border: 3.05909px solid rgba(0, 108, 97, 0.3);
  border-radius: 50%;
}

.header-quiz {
  grid-row: 1;
  width: 100%;
  height: 100px;
  display: grid;
  grid-template: 100px / 1fr 1fr 1fr;
  place-items: start center;
  position: relative;
}

.logo {
  position: absolute;
  top: 40%;
  left: 25%;
  transform: translate(-50%, -25%);
  grid-column: 2;
  cursor: pointer;
}

.main {
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 235px - 100px);
  flex: 1 0 auto;
}

@media screen and (max-width: 601px) {
  .quiz {
    .login_header_button_back {
      left: 17px;
    }
  }

  .quiz footer {
    height: 60px;
    position: fixed;
    bottom: 0;

    .square-bottom-left, .square-bottom-right, {
      transform: scale(0.4, 0.4);
    }

    .progress-bar{
      //bottom: 80px;
      transform: scaleX(1.5);
      small{
        transform: scaleX(0.66);
      }
    }
    .square-bottom-left {
      transform-origin: left;

      .full-circle-left, .circle-left {
        display: none;
      }
    }

    .square-bottom-right {
      transform-origin: right;
    }
  }
}

@media screen and (max-height: 500px) {
  .quiz footer {
    display: none;
  }
}
