.progress-bar {
  background-color: #F5F5F5;
  width: 30vw;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  bottom: 32px;
  left: 35vw;

  small {
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 13px;
    line-height: 17px;
  }

  .bar-top {
    background-color: #006C61;
    height: 4px;
    border-radius: 2px;
  }
}
