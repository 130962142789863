html, body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
