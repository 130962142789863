@import "../../assets/styles/variable";
.account {
  position: relative;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.account_head_img {
  background-image: url("../../assets/static/account_img/191212_Biomother Location_10_Mikaela Bed_1021 1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 95px 65px;
  border-radius: 16px;
  margin: 40px 0;
  text-align: left;
  p {
    color: $white;
    font-weight: bold;
    font-size: 40px;
    margin: 0;
  }
}
.account_body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.account_body_left {
  max-width: 30%;
  width: 100%;
}
.account_body_right {
  max-width: 60%;
  width: 100%;
  margin-bottom: 30px;
  //background-color: #006C61;
  border-radius: 16px;
  .recommendation{
    .recommendation-options{
      margin-bottom: 15px;
    }
    .recommendation_plus_icon{
      display: none;
    }
    .recommendation_recommended_title{
      display: none;
    }
  }
}
.active_menu {
  color: $primary_one;
}
.account_body_left_side_menu {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
  li {
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    padding: 17px 0;
    color: $account_side_menu;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 6px;
      height: 11px;
      background-image: url("../../assets/static/svg/Chevron-right.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
    &:hover {
      color: $primary_one_hover;
    }
    &:active {
      color: $primary_one_active;
    }
  }
}
@media (max-width: 600px) {
  .account_body {
    flex-direction: column;
  }
  .account_body_left {
    max-width: 100%;
  }
  .account_body_right {
    max-width: 100%;
  }
  .account_head_img {
    padding: 95px 25px 35px 25px;
    p {
      font-size: 40px;
    }
  }
  .account_body_left_side_menu {
    margin-bottom: 30px;
    .active_menu {
      color: $primary_one;
      transition: all .5s ease;
      &:after {
        transform: rotate(90deg) translateX(-50%);
      }
    }
  }
  .account_body_left_side_menu {
    text-align: center;
  }
  .control {
    display: none;
  }
}
