@import "../../../assets/styles/variable";
.settings_account {
  border-radius: 16px;
  border: 1px solid rgba(196, 196, 196, 0.3);
  padding: 32px 32px;
  position: relative;
}
.settings_account_title {
  font-weight: bold;
  font-size: 28px;
  color: $black;
  margin: 0;
  text-align: left;
}
.success_message, .error_message{
  color: #006c61;
  font-family: GilroyRegular,sans-serif;
  font-size: 14px;
  height: 27px;
  padding: 5px 0;
  margin-bottom: 5px;
}
.error_message{
  color: #BF2F29;
}
.settings_account_item {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
  input{
    max-width: 300px ;
    width: 100%;
  }
  .settings_account_password{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    button{
      max-width: 120px;
      width: 100%;
    }
  }
}
.settings_account_name {
  width: 100%;
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 16px 0;
    color: $black;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
  }
}
.settings_social_sign_in_wrapper {
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 32px 0 16px 0;
    color: $black
  }
  p {
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 16px 0;
  }
}
.social_button_wrapper {
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
}
.settings_account_delete {
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
}
.settings_account_delete_left {
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 16px 0;
    color: $black;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    color: $black;
    margin: 0;
  }
}
.settings_account_delete_right {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: $black;
}
.settings_account_logout {
  text-align: left;
  font-weight: 600;
  padding-right: 20px;
  margin-top: 32px;
  font-size: 18px;
  color: $black;
  span {
    cursor: pointer;
    position: relative;
    padding-right: 35px;
    &:after {
      position: absolute;
      content: url("../../../assets/static/icon/log-out.svg");
      right: 0;
    }
  }
}
@media (max-width: 600px) {
  .settings_account {
    padding: 32px 20px;
  }
  .social_button_wrapper {
    button {
      width: 100%;
      padding-left: 16px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .settings_account_delete {
    align-items: flex-start;
  }
}
