@import "src/assets/styles/variable.scss";
.recommendation-details_whatInside {
  background-color: red;
  max-height: max-content;
  background-color: rgba(104, 140, 210, 0.1);
  padding: 20px;
  border-radius: 12px;
  .what_inside{
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}

.recommendation-details-insideTitle{
  font-size: 18px;
  font-weight: 700;
}
.recommendation-details-insideText{
  margin-top: 10px;
  font-size: 14px;
  color: $color;
}
