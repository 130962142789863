.registration-form {
  text-align: center;
  h1 {
    //width: 546px;
    font-size: 32px;
    line-height: 40px;
    margin: 0 auto 10px;
  }

  fieldset {
    width: min-content;
    height: min-content;
    padding: 0;
    margin: 0;
    border: none;
    place-self: center;
  }

  span {
    display: inline-block;
    margin: 0 auto 20px;
    font-size: 20px;
    line-height: 26.6px;
    width: 480px;
    text-align: center;
    &:nth-of-type(2) {
      width: 321px;
      place-self: center;
      font-size: 14px;
      a {
        color: #006c61;
      }
    }
  }

  .custom-input {
    width: 400px;
    place-self: center;
  }

  button {
    @extend .custom-input;
    @media (max-width: 600px) {
      width: 253px;
    }
  }
}
