@import "../../assets/styles/variable";
.login {
  padding: 20px 0;
  height: 100vh;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.error_login_msg{
  display: block;
  font-size: 14px;
  color: $invalid_border;
  font-weight: 500;
  text-align: start;
  margin: 0;
  padding: 0;
}
.login_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.log_in_container {
  text-align: center;
  max-width: 420px;
  padding: 0 10px;
  margin: 0 auto;
  .login_form{
    position: relative;
    .loader_icon{
      width: 40px;
      position: absolute;
      left: 44%;
      bottom: -40%;
    }

  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    color: $black;
    line-height: 40px;
    margin: 0 0 32px 0;
  }
  input {
    margin-bottom: 12px;
  }
  button {
    width: 100%;
    margin-top: 22px;
  }
  div {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 16px;
    color: $primary_one;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
      color: $primary_one_hover;
    }
    &:active {
      color: $primary_one_active;
    }
  }
  p {
    margin-bottom: 15px;
  }
}
.login_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 100px;
  .div {
    width: 55px;
  }
}
.login_footer {
  text-align: center;
  div {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    cursor: pointer;
  }
  .log_in_create_account {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $primary_one;
    margin-top: 8px;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
      color: $primary_one_hover;
    }
    &:active {
      color: $primary_one_active;
    }
  }
}

.login_header_button_back {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 16px;
  position: relative;
  cursor: pointer;
  color: $black;
  &:before {
    transform: rotate(180deg);
    position: absolute;
    content: url("../../assets/static/svg/Chevron-right.svg");
    left: 0;
    top: 0;
    height: 13px;
  }
}
p {
  font-weight: normal;
  font-size: 20px;
  color: $black;
  line-height: 27px;
}
.element_left {
  position: absolute;
  top: 88%;
  right: 92%;
  border-radius: 50%;
  background-color: rgba(120, 189, 180, 0.5);
  width: 302px;
  height: 282px;
}
.element_right {
  position: absolute;
  top: 82%;
  left: 88%;
  border-radius: 50%;
  background-color: rgba(120, 189, 180, 0.5);
  width: 480px;
  height: 450px;
}
.element_left_two {
  position: absolute;
  border: 2px solid $primary_one_active;
  top: 86%;
  right: 93%;
  border-radius: 50%;
  width: 302px;
  height: 282px;
}
.element_right_two {
  position: absolute;
  border: 2px solid $primary_one_active;
  top: 84%;
  left: 86%;
  border-radius: 50%;
  width: 480px;
  height: 450px;
}
@media (max-width: 600px) {
  .log_in_container {
    padding: 0 25px;
    h1 {
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 30px 0;
    }
    button {
      width: 100%;
      margin-top: 30px;
    }
    img {
      max-width: 100%;
    }
  }
  .login_header {
    padding: 0 25px;
    align-items: center;
    .div {
      width: 55px;
    }
  }
  p {
    font-weight: normal;
    font-size: 20px;
    color: $black;
    line-height: 27px;
  }
  .element_left {
    position: absolute;
    top: 80%;
    right: 82%;
    border-radius: 50%;
    background-color: rgba(120, 189, 180, 0.5);
    width: 302px;
    height: 282px;
  }
  .element_right {
    position: absolute;
    top: 72%;
    left: 78%;
    border-radius: 50%;
    background-color: rgba(120, 189, 180, 0.5);
    width: 480px;
    height: 450px;
  }
  .element_left_two {
    position: absolute;
    border: 2px solid $primary_one_active;
    top: 76%;
    right: 83%;
    border-radius: 50%;
    width: 302px;
    height: 282px;
  }
  .element_right_two {
    position: absolute;
    border: 2px solid $primary_one_active;
    top: 64%;
    left: 86%;
    border-radius: 50%;
    width: 480px;
    height: 450px;
  }
}
