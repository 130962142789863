@import "../../../assets/styles/variable";

.primary_button_orange,
.primary_button_blue,
.primary_button_green,
.secondary_button_green,
.quiz_button_gray,
.secondary_button_gray {
  font-size: 14px;
  text-transform: capitalize;
  padding: 20px 20px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.04em;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color .5s ease;
  min-width: 100px;
}

.secondary_button_white {
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px 16px;
  border-radius: 8px;
  border: 2px solid $secondary_two;
  background-color: inherit;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  transition: background-color .5s ease;
  min-width: 100px;
}

.secondary_button_gray {
  background-color: $secondary_one;
  color: $color;
  @media (hover: hover) {
    &:hover {
      background-color: $secondary_one;
      color: $secondary_one_hover_text;
      transition: background-color .5s ease;
    }
  }

  &:active {
    background-color: $secondary_one_active;
    color: $secondary_one_hover_text;
    transition: background-color .5s ease;

  }

  &:disabled {
    opacity: 0.5;
    transition: background-color .5s ease;

  }
}

.quiz_button_gray {
  background-color: $secondary_one;
  color: $color;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: rgba(0, 108, 97, 0.1);
      color: $primary_one;
      transition: background-color .5s ease;
    }
  }

  &:active {
    background-color: rgba(0, 108, 97, 0.1);
    color: $primary_one_active;
    transition: background-color .5s ease;
  }
}

.secondary_button_green {
  background-color: rgba(0, 108, 97, 0.1);
  color: $primary_one;
  transition: background-color .5s ease;
  @media (hover: hover) {

    &:hover {
      color: $primary_one_hover;
      transition: background-color .5s ease;

    }
  }

  &:active {
    color: $primary_one_active;
    transition: background-color .5s ease;

  }

  &:disabled {
    opacity: 0.5;
  }
}

.secondary_button_white {
  border-color: $secondary_two;
  color: $secondary_two;
  @media (hover: hover) {

    &:hover {
      border-color: $secondary_two_hover;
      color: $secondary_two_hover;
    }
  }

  &:active {
    border-color: $secondary_two_active;
    color: $secondary_two_active;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.primary_button_orange {
  background-color: #E35F5F;
  color: $white;
  transition: background-color .5s ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  @media (hover: hover) {

    &:hover {
      background-color: #e06363;
    }
  }

  &:active {
    background-color: #EB8F8F;
  }

  &:disabled {
    opacity: 0.5;
  }

  text-transform: none;
}

.primary_button_green {
  background-color: $primary_one;
  color: $white;
  transition: background-color .5s ease;
  @media (hover: hover) {

    &:hover {
      background-color: $primary_one_hover;
    }
  }

  &:active {
    background-color: $primary_one_active;
  }

  &:disabled {
    opacity: 0.5;
  }

  text-transform: none;
}

.primary_button_blue {
  background-color: $primary_two;
  color: $white;
  transition: background-color .5s ease;
  @media (hover: hover) {

    &:hover {
      background-color: $primary_two_hover;
    }
  }

  &:active {
    background-color: $primary_two_active;
  }

  &:disabled {
    opacity: 0.5;
  }
}
