@import "src/assets/styles/variable.scss";

.header_main {
  position: relative;
  top: 0;
  right: 0;
  padding: 20px 0;
  width: 100%;
  z-index: 30;

  .headerContainer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header_main_cart_wrapper {
  display: flex;
  position: relative;

  span {
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 3px solid white;
    border-radius: 10px;
    position: absolute;
    right: -3px;
    top: -6px;
    background-color: indianred;
  }
}

.header_main_left {
  display: flex;
  align-items: center;
  max-width: 310px;
  width: 100%;

  img {
    margin-right: 44px;
    cursor: pointer;
  }

  p {
    font-family: "Gilroy", sans-serif;
    margin: 0;
    line-height: 22.5px;
    font-weight: 600;
    font-size: 18px;
    color: #494A53;
    cursor: pointer;
  }
}

.header_main_trust_link {
  &:hover {
    color: $primary_one;
  }

  &:active {
    color: $primary_one_active;
  }
}

.header_main_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 88px;
  width: 100%;

  .header_main_right_first_icon_wrapper {
    display: flex;
    align-self: flex-end;
    width: 100%;
    justify-content: space-between;
  }

  img {
    cursor: pointer;
    //max-width: 35px;
  }

  .mobile_icon_1 {
    max-width: 24px;
    width: 24px;
    height: 24px;
  }



  .mobile_icon_2,
  .mobile_icon_1 {
    position: relative;
    transition: all .3s ease;
    border-radius: 5px;

    &:hover {
      transition: all .3s ease;
      background-color: $secondary_one;
    }

    &:active {
      transition: all .3s ease;
      background-color: $colorGrey;
    }

    //&:before {
    //  position: absolute;
    //}
  }

  .mobile_icon_3 {
    display: none;
  }

}

@media (max-width: 600px) {
  .header_main {
    padding: 20px 15px;

    .headerContainer {
      margin: 0;
      padding: 0;
    }
  }
  .header_main_right {
    justify-content: flex-start;
  }
  .mobile_icon_1 {
    display: none;
    width: 0;

  }
  .mobile_icon_2 {
    width: 35px;
    height: 35px;
    //margin-right: 25px;
  }
  .header_main_right {
    .mobile_icon_3 {
      display: flex;
      margin-left: 10px;
    }
  }
}
