.container{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.first_block h1{
  max-width: 560px;
  width: 100%;
  height: 100px;
  color: #383942;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  margin: 60px 0 16px 0;
}
.second_block h1{
  max-width: 560px;
  width: 100%;
  height: 58px;
  color: #383942;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  margin: 90px 0 16px 0;
}
.text1{
  max-width: 677px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #383942;
  margin: 0 0 40px 0;
}
.text2{
  max-width: 677px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin: 0 0 50px 0;
}
.first_block_content{
  display: flex;
  max-height: 290px;
}
.first_block_content_item1{
  width: 204px;
  min-height: 100%;
  padding: 20px;
  background: #D56663;
  border-radius: 16px;
  margin-right: 31px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
}
.first_block_content_item2{
  width: 204px;
  min-height: 100%;
  padding: 20px;
  background: #006C61;
  border-radius: 16px;
  margin-right: 31px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
}
.first_block_content_item3{
  width: 204px;
  min-height: 100%;
  padding: 20px;
  background: #E0A019;
  border-radius: 16px;
  margin-right: 31px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

}
.first_block_content_item4{
  width: 204px;
  min-height: 100%;
  padding: 20px;
  background: #4F7399;
  border-radius: 16px;
  margin-right: 31px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
}
.first_block_content_item5{
  width: 204px;
  min-height: 100%;
  padding: 20px;
  background: #6D66B3;
  border-radius: 16px;
  margin-right: 31px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
}

.second_block{
  margin-top: 100px;
  display: flex;
  justify-content: center;
  background-color: #F5F5F5;
}
.second_block_text {
  padding-bottom: 30px;
}
.second_block_main{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.second_block_main_button{
  cursor: pointer;
  display: flex;
  padding: 19px 20px;
  background: #006C61;
  border-radius: 8px;
  border: none;
  color:#F5F5F5;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: all .5s ease;
  margin: 30px 0 60px;

}
.second_block_main_button:hover{
  background: #28977D;
}
.second_block_main_button:active{
  background: #034942;
}
.second_block_main_button:disabled {
  background: #006C61;
}

.third_block{
  margin-top: 118px;
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;;
}
.third_block_content{
  display: flex;
}
.third_block_content_article{
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  max-width: 558px;
  width: 100%;
  height: 555px;
  margin-bottom: 80px;
}
.third_block_content_article_logo{
  width: 128px;
  height: 62px;
  margin-bottom: 15px;
}
.third_block_content_article h1{
  height: 82px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 120%;
  color: #383942;
  margin: 20px 0;
}
.third_block_content_article p{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

}
.third_block_content_p2{
  margin: 35px 0;
}
.third_block_content_p3{
  margin-bottom: 15px;
}
.third_block_content_article_img{
  Width: 556px;
  Height: 346px;
  border-radius: 16px;
}
.third_block_content_article a{
  font-weight:600;
  font-style:normal;
  font-size:16px;
  line-height: 24px;
  color: #006C61;
  text-decoration: none;
}
.third_block_content_article a::after{
  content: "->";
}

@media (max-width: 600px) {
  body{
    margin: 0;
    padding: 0;
  }
  .container{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .second_block_text {
    padding-bottom: 0;
  }
  .header{
    width: 100%;
    height: 159px;}
  .first_block,.second_block, .third_block{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .first_block h1{
    width: 100%;
    font-size: 30px;
    margin-top:20px;
    margin-bottom: 10px;
  }
  .second_block_main_item {
    img {
      margin-top: 20px;
      margin-left: 0;
    }
    p {
      margin-left: 0;
    }
  }
  .text1 {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  .first_block_content{
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .first_block_content_item1{
    width: 100%;
    height: auto;
    margin-top:40px;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 20px;
    font-size: 16px;
  }
  .first_block_content_item2{
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 20px;
    font-size: 16px;
  }
  .first_block_content_item3{
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 20px;
    font-size: 16px;
  }
  .first_block_content_item4{
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 20px;
    font-size: 16px;
  }
  .first_block_content_item5{
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
    padding: 20px;
    font-size: 16px;
  }
  .second_block{
    margin: 0;
    .container {
      padding: 60px 30px;
    }
  }
  .second_block h1{
    margin: 0 0 24px 0;
    padding-left: 0;
    max-width: 315px;
    font-size: 30px;
    line-height: 30px;
    color: #383942;
  }
  .text2{
    width: 325px;
    margin: 0 0 24px 0;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .second_block_main_button{
    width: 315px;
    height: 74px;
    margin: 64px 30px 60px 30px;
  }
  .third_block{
    margin: 0;
    width: 100%;
  }
  .third_block_content{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 130px;
  }
  .third_block_content_article{
    width: 100%;

  }
  .third_block_content_article_img{
    max-width: 100%;
    min-height: 346px;
    margin: 60px 0 31px 0;

  }
  .third_block_content_article_logo{
    margin: 0 0 20px 0;
  }
  .third_block_content_article h1{
    width: 100%;
    padding: 0 35px 20px 0;
  }
  .third_block_content_p1{
    width: 100%;
    padding-left: 0;
    padding-right: 25px;
  }
  .third_block_content_p2{
    width: 100%;
    padding-left: 0;
    padding-right: 25px;
  }
  .third_block_content_p3{
    width: 100%;
    padding-left: 0;
    padding-right: 25px;
  }
  .third_block_content_article a{
    width: 100%;
    padding-left: 0;
  }
}
