@import "../../../assets/styles/variable";

.custom-input {
  padding: 16px 20px 16px 20px;
  font-size: 16px;
  background-color: $secondary_one;
  border: 1px solid $secondary_one;
  outline: none;
  border-radius: 8px;
  width: 100%;

  &:focus {
    border-color: $primary_one_active;
  }

  &:invalid {
    border-color:$invalid_border ;
    color: $invalid_border;
  }
}

.custom-input_error {
  border-color:$invalid_border!important;
  color: $invalid_border;
}
