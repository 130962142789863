.darkbg {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 30px;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  * {
    margin: 0;
  }
  .quizmodal {
    .header-quiz-modal {
      margin-top: 72px;
      display: grid;
      grid-template-rows: min-content min-content;
      grid-template-columns: 117px auto;
    }
    width: 63%;
    position: relative;
    border-radius: 16px;
    align-self: center;
    background-color: white;
    overflow: hidden;
    padding-bottom: 20%;
    height: 100%;

    .closeBtn {
      width: 20px;
      height: 20px;
      margin-left: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
    h1 {
      width: 100%;
      padding-right: 79px;
      grid-column: 2;
    }
    .text {
      height: 100%;
      overflow: auto;
      margin: 56px 79px 0 117px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .terms_title {
        font-size: 25px;
        margin-bottom: 20px;

        &:first-child {
          margin-bottom: 0;
        }
      }

      .terms_list {
        li {
          font-size: 20px;
          margin-left: 35px;

          @media (max-width: 600px) {
            font-size: 18px;
            margin-left: 35px;
          }
        }

        li:last-child {
          margin-bottom: 20px;
        }

        &_header {
          margin-bottom: 20px;
          text-transform: uppercase;
          font-size: 22px;
        }

        &_inner {
          margin-top: 20px;
          padding-left: 30px;
          list-style-type: disc;

          li {
            margin-bottom: 10px;
          }
        }
      }
      .terms_content_title {
        text-align: center;
        font-weight: 700;
        font-size: 39px;
        margin: 79px 0 59px;
      }

      .terms_content_text {
        display: flex;
        flex-direction: column;
        gap: 25px;
        max-width: 680px;
        width: 100%;
        margin: 0 auto 128px;

        .terms_content_paragraph {
          font-size: 16px;
          font-family: GilroyRegular, sans-serif;
          line-height: 24px;

          &_margin {
            @extend .terms_content_paragraph;
            margin-bottom: 20px;
          }

          &_inner {
            @extend .terms_content_paragraph;
            margin: 0 30px 30px;
            font-weight: 700;
          }
        }
      }
    }
    *:not(button) {
      color: black;
    }
    .bottom-button-wrapper {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -20px;

      button {
        max-width: 300px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1000px){
  .darkbg{
    .quizmodal {
      h1{
        font-size: 22px;
        line-height: 22px;
      }
      .text{
        margin: 36px 59px 0 97px;
        h2{
          font-size: 18px;
        }
        p{
          font-size: 16px;
        }
      }
    }
  }

}


@media screen and (max-width: 601px) {
 .darkbg{
   padding-bottom: 30px;
   overflow-y: scroll;
   .quizmodal{
     display: flex;
     flex-direction: column;
     padding-bottom: 5%;
     width: 90%;
     height: min-content;
     overflow-y: scroll;
     overflow-x: hidden;
     .header-quiz-modal{
       display: flex;
       text-align: center;
       margin-top: 30px;
       h1{
         font-size: 22px;
         line-height: 29px;
         font-weight: 700;
         padding-right: 0;
       }
     }
     .text{
       margin: 26px 26px 35px 26px;
       height: 100%;
       h2{
         font-size: 16px;
         line-height: 21px;
         font-weight: 600;
       }
       p{
         color: #383943;
         font-size: 16px;
         font-family: 'Gilroy', sans-serif;
         font-weight: 400;
         line-height: 24px;
       }
     }
     .bottom-button-wrapper {
       position: static;
     }
   }
 }
}
