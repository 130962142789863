@import "src/assets/styles/variable";

.terms_and_conditions {
  max-width: 1286px;
  width: 97%;
  margin: 0 auto;
  padding: 20px 16px;

  .terms_main_logo {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
  }

  .terms_title {
    font-size: 30px;
    margin-bottom: 20px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  .terms_list {
    li {
      font-size: 22px;

      @media (max-width: 600px) {
        font-size: 20px;
        margin-left: 25px;
      }
    }

    li:last-child {
      margin-bottom: 20px;
    }

    &_header {
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 22px;
    }

    &_inner {
      margin-top: 20px;
      padding-left: 30px;
      list-style-type: disc;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .terms_content {
    .terms_content_title {
      text-align: center;
      font-weight: 700;
      font-size: 39px;
      margin: 79px 0 59px;
    }

    .terms_content_text {
      display: flex;
      flex-direction: column;
      gap: 25px;
      max-width: 680px;
      width: 100%;
      margin: 0 auto 128px;

      .terms_content_paragraph {
        font-size: 16px;
        font-family: GilroyRegular, sans-serif;
        line-height: 24px;

        &_margin {
          @extend .terms_content_paragraph;
          margin-bottom: 20px;
        }

        &_inner {
          @extend .terms_content_paragraph;
          margin: 0 30px 30px;
          font-weight: 700;
        }
      }
    }
  }

  .terms_footer {
    display: flex;
    justify-content: space-between;

    p {
      font-family: GilroyRegular, sans-serif;
      color: #b2b2b5;
      font-size: 13px;
    }
  }

  .header-rec-backBtn {
    max-width: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 8px
    }

    p {
      font-size: 14px;
      color: #383942;
      font-weight: 700;
      line-height: 0;
    }
  }
}

.terms_red {
  color: $invalid_border;
}

.terms_blue {
  color: #2bc0ff;
}

@media  (max-width: 600px){
  .terms_and_conditions{
   .terms_and_conditions_header{
     display: flex;
     justify-content: space-between;
     flex-direction: row-reverse;

   }
    .terms_content{
      .terms_content_title{
        font-size: 30px;
        margin: 48px 0 36px;
      }
      .terms_content_text{
        margin-bottom: 30px;
      }
    }
  }
  .terms_footer{
    flex-direction: column;
    align-items: center;
    img {
      max-width: 112px;
      margin-bottom: 80px;
    }
  }
}
