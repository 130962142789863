.loader_icon {
  width: 80px;
  animation: loaderAnimation .8s infinite linear ;
  margin: 0 auto;
}

@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg) ;
  }
  100% {
    transform: rotate(-360deg);
  }
}
