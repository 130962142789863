@import "src/assets/styles/variable.scss";

.recommendation {

  .recommendation_recommended_title {
    background-color: $primary_one;
    position: absolute;
    color: $white;
    text-transform: uppercase;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 12px;
    top: 4%;
    z-index: 10;
    left: 7%;
  }


  .recommendation-options {
    margin-bottom: 30px;
  }

  .recommendation_plus_icon_mobile {
    display: none;
  }

  .recommendation-program_account,
  .recommendation-program {
    max-width: 1221px;
    width: 100%;
    padding: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 2px solid $border_color;
    border-radius: 16px;
    position: relative;
  }

  .program-item_images {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .rec_label_container{
      width: 100%;
      position: absolute;
      top: -15px;
      padding-right: 7px;
    }
  }

  .recommended_label {

    max-width: 108px;
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    background: #006C61;
    backdrop-filter: blur(10px);
    padding: 5px 8px;
    border-radius: 12px;
    color: white;
    margin: 0 auto;
  }

  .recommendation-program::before {
    position: absolute;
    content: "Prepare for birth programme";
    background-color: $secondary_one;
    max-width: 375px;
    width: 100%;
    color: #383942;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 10px 16px;
    font-weight: 700;
    font-size: 22px;
    border-radius: 16px;
  }

  .recommendation-program_account::before {
    display: none;
  }

  .recommendation-program_items {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .recommendation-program_items_account {
    width: 100%;

    .recommendation-program_item_account {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 100%;
      width: 100%;
      margin: 0 0 20px 0;
    }

    .program-item_img {
      margin-left: 0;
    }
  }

  .loader_wrapper {
    text-align: center;
    padding: 100px 0;
  }

  .recommendation-program_item {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .program-item_img {
    display: block;
    border-radius: 50%;
    min-height: 200px;
    max-width: 200px;
  }

  .program-item_title {
    font-weight: 700;
    font-size: 20px;
    margin: 16px 0;
  }

  .program-item_text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
    color: $secondary_one_hover_text;
    font-weight: 400;
  }

  .recommendation-program_account {
    .recommendation-program_link {
      margin-bottom: 20px;
    }

  }

  .programme_button_wrapper {
    text-align: right;
  }

  .recommendation-program_link {
    cursor: pointer;
    color: $primary_one;
    font-size: 16px;
    font-weight: 600;
    //   position: relative;
  }

  .recommendation-program_link::after {
    position: absolute;
    content: "";
    background-image: url("../../../assets/static/icon/arrowRightPrimary.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    left: 150px;
  }

  .recommendation-buttons {
    display: flex;
    margin: 20px 0 50px;
  }

  .recommendation-buttons_container {
    margin: 0 auto;
    max-width: 360px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px 0;
  }

  .rec-btn-add,
  .rec-btn-save {
    font-weight: 700;
    font-size: 14px;
    padding: 19px 22px;
    color: $white;
    outline: none;
    border-radius: 8px;
    border: none;
    text-transform: uppercase;
    background-color: $primary_one;
    cursor: pointer;
  }

  .rec-btn-save {
    background-color: $secondary_one;
    color: $color;
  }


  @media (max-width: 600px) {
    .recommendation {
      padding: 0 0;


    }
    .recommendation_recommended_title {
    position: inherit;
      display: block;
      max-width: 107px;
      margin:  0 auto 15px;
    }
    .recommendation_plus_icon_mobile {
      display: block;
      margin: 12px auto 30px;
    }
    .recommendation_plus_icon {
      display: none;
    }
    .recommendation-buttons_container {
      max-width: 100%;

    }
    .recommendation-program {
      border: none;
      max-width: 100%;
      padding: 40px 0 0 0 ;
      &::before{
        background-color: #fff;
        top: -44px;
      }
    }
    .recommendation-program_items {
      max-width: 100%;
      flex-wrap: wrap;
      gap: 35px;
    }
    .recommendation-program_item {
      max-width: 100%;
      width: 100%;
      .recommended_label{
        left: 59px;
      }
    }
    .program-item_img {
      display: block;
      margin: 0 auto;
    }
    .program-item_title {
      font-size: 22px;
    }
    .program-item_text {
      font-size: 16px;
      line-height: 22px;
    }
    .recommendation-buttons_container {
      flex-wrap: wrap;
      width: 100%;
    }
    .primary_button_green, .secondary_button_gray {
      width: 100%;
      margin-bottom: 16px;
    }
    .recommendation-buttons {
      margin: 15px;
    }
    .recommendation-supplements {
      max-width: 100%;
      width: 100%;
    }
    .recommendation-supplements_items {
      flex-wrap: wrap;
      padding-bottom: 10px;
    }
    .supplements-item {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
    .supplements-item_img {
      max-width: 100%;
      width: 100%;
    }
  }

  .slick-list {
    max-width: 1000px;
  }

  .slick-track {
    max-width: 1000px;
    z-index: 500;
  }

}

.recommendation-program {
  max-width: 1221px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 2px solid $border_color;
  border-radius: 16px;
  position: relative;
}

.recommendation-program_items {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

