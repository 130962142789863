.input-form {
  .custom-input {
    width: 400px;
    place-self: center;
  }

  button {
    width: 400px;
    place-self: center;

    @media (max-width: 600px) {
      width: 253px;
    }
  }

  h1 {
    padding: 0;
    line-height: inherit;
    height: 40px;
  }

  p {
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    place-self: center;
  }

  a {
    @extend p;
    text-decoration: underline;
    &:visited {
      color: black;
    }
  }

}

@media screen and (max-width: 601px) {
  .input-form {
    h1 {
      font-size: 28px;
    }
  }
}
