.banner_item {
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 12px 20px;
  background-color: #006C61;
  position: relative;
  font-size: 16px;
  line-height: 21px;
  font-family: GilroySemi, sans-serif;

}

.banner_close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}
@media (max-width: 650px) {
  .banner_item{
    text-align: left;
  }

}