@import "src/assets/styles/variable.scss";

.loader_icon {
  width: 80px;
  animation: loaderAnimation .8s infinite;
}

@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-36deg);

  }
  20% {
    transform: rotate(-72deg);

  }
  30% {
    transform: rotate(-108deg);

  }
  40% {
    transform: rotate(-144deg);

  }
  50% {
    transform: rotate(-180deg);

  }
  60% {
    transform: rotate(-216deg);

  }
  70% {
    transform: rotate(-242deg);

  }
  80% {
    transform: rotate(-278deg);

  }
  90% {
    transform: rotate(-314deg);

  }
  100% {
    transform: rotate(-360deg);

  }
}

#cardNumber, #card-exp, #card-cvc {
  padding: 16px 20px 16px 20px;
  font-size: 16px;
  background-color: $secondary_one;
  border: 1px solid $secondary_one;
  outline: none;
  border-radius: 8px;
  width: 48%;
  color: #383942;
  text-align: center;

}

#cardNumber {
  &::placeholder {
    color: blue;
  }
}

#test {
  display: flex;
}

.autocomplete_items {
  max-width: 198px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  top: 92%;
  left: 34%;
  z-index: 10;
  background-color: #c4c4c4;
  border-radius: 7px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  p {
    color: #006c61;
    font-size: 16px;
    margin-right: 10px;
  }
}

.autocomplete_item {
  width: 100%;

  font-size: 14px;
  padding: 3px 0 3px 20px;
  transition: all .3s linear;
  color: $black;


  &:hover {
    background-color: #fff;
    cursor: pointer;
    transform: scale(1.2);
    color: #000;
  }
}


.input-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;

  .card-error {
    position: absolute;
    top: -24px;
    font-size: 13px;
    font-family: GilroyBold, sans-serif;
    color: $danger_color;
  }

  #cardNumber {
    width: 55%;
  }
}

#card-exp, #card-cvc {
  width: 20%;
}

.checkout {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer_main{
    .footer_center{
      max-width: 340px;
      width: 100%;
    }
  }
  .payment_error {
    background-color: #FCEFEF;
    margin: 28px 0 20px 0;
    width: 100%;
    transition: all 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 25px;
      text-align: center;
      font-weight: 700;
      color: #E35F5F;
    }

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      h3 {
        font-size: 20px;
        line-height: 118%;
        max-width: 250px;
      }
    }
  }

  .recommended_label {
    display: none;
  }

  .checkout_error {
    position: absolute;
    top: -24px;
    font-size: 13px;
    font-family: GilroyBold, sans-serif;
    color: $danger_color;
  }

  .input_wrapper {
    position: relative;
  }

  .checkout-container {
    .payment-details-header {
      max-width: 1000px;
      width: 100%;
      margin: 35px auto;
    }

    .checkout_main_form {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
    }

    .recommendation {
      .recommendation-program {
        .recommendation-program_items {
          .recommendation-program_item {
            max-width: 280px;
            width: 100%;
            align-items: flex-start;

            .program-item_title {

            }
          }
        }

        .recommendation_recommended_title {
          display: none;
        }
      }
    }
  }

  & > h1:nth-of-type(1) {
    text-transform: capitalize;
  }

  .checkout_modal {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background-color: #38394350;
    max-width: 100%;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    h2 {
      margin-top: 20px;
    }

    .subscription_firstBlock, .header-rec, .footer-rec {
      display: none;
    }

    .subscription_main {
      max-width: 920px;
      background: #FCFCFC;
      border-radius: 16px;
      padding: 20px;
      margin-top: 10%;

      .subscription_secondBlock {
        max-width: 564px;
        width: 100%;

        .current, .subscription_item {
          max-width: 100%;
        }
      }

      .subscription-bottom-text {
        max-width: 472px;
        width: 100%;
        font-family: GilroyRegular, sans-serif;
        color: #383942;
        font-weight: 400;
        font-size: 14px;
      }

      .close_icon_btn {
        cursor: pointer;
        margin: 0 auto 0 20px;
      }

      .subscription_button_wrapper {
        max-width: 440px;
        width: 100%;

        .primary_button_green {
          background-color: #E35F5F;
          max-width: 440px;
          width: 100%;
        }
      }

    }
  }

  .change-plan-link {
    margin: 8px 0 28px 0;

    a {
      color: $primary_one;
      text-decoration: underline;
    }
  }

  Input {
    &:invalid {
      border: 1px solid transparent;
    }
  }

  &-checkBox_container {
    display: flex;
    align-items: center;
  }

  &-checkBox_input {
    width: 15px;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .recommendation-program_link {
      display: none;
    }
  }

  &-cardInfo {
    width: 100%;
    display: flex;
    flex-direction: column;

    .checkout-cardInfo_general_inputs {
      display: flex;
      gap: 88px;
      align-items: baseline;
      flex-wrap: wrap;

      .promocode-input-container {
        max-width: 430px;
        width: 100%;
      }

      .promocode_title {
        display: block;
        margin-bottom: 17px;
        font-size: 28px;
        font-weight: 700;
      }

      .payment-method-container {
        max-width: 445px;
        width: 100%;

        .input-container {
          width: 100%;

          #cardNumber {
            width: 100%;
          }
        }
      }
    }

    .checkout-cardInfo_address_inputs {
      display: flex;
      gap: 88px;
      flex-wrap: wrap;

      .shipping-wrapper {
        max-width: 445px;
        width: 100%;
      }

      .billing-wrapper {
        max-width: 430px;
        width: 100%;

        .sameAddress_container {
          display: flex;
          align-items: center;
          transform: translateY(-3px);

          .same_address_text {
            margin-left: 8px;
            color: #6C6D74;
            font-size: 16px;
            line-height: 22px;
            font-family: GilroyLight, sans-serif;
          }
        }

        .round_monthly {
          position: relative;
        }

        .round_monthly label {
          background-color: #fff;
          border: 2px solid #ccc;
          border-radius: 20%;
          cursor: pointer;
          height: 20px;
          right: 0;
          position: absolute;
          bottom: 0;
          width: 20px;
        }

        .round_monthly label:after {
          border: 3px solid #29BF2F;
          border-top: none;
          border-right: none;
          content: "";
          height: 3px;
          left: 3px;
          opacity: 0;
          position: absolute;
          top: 4px;
          transform: rotate(-45deg);
          width: 8px;
        }

        .round_monthly input[type="checkbox"] {
          visibility: hidden;
        }

        .round_monthly input[type="checkbox"]:checked + label {
          background-color: #fff;
        }

        .round_monthly input[type="checkbox"]:checked + label:after {
          opacity: 1;
        }
      }
    }

    .checkout-cardInfo_footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 90px;

      .submitWrapper {
        max-width: 445px;
        width: 100%;
      }

      .bianu-promise {
        max-width: 460px;
        width: 100%;
        img{
          max-width: 152px;
        }

        .bianu_promise_title {
          font-family: Gilroy, sans-serif;
          font-weight: 700;
          font-size: 31px;
          line-height: 36px;
          text-align: left;
        }
      }
    }

    .payment_account_buttons {
      display: flex;
      justify-content: flex-start;
      gap: 15px;

      button {
        width: 30%;
      }
    }

    .submitWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .payment-summary-container {
        margin-top: 31px;
      }

      button {
        background-color: #E35F5F;
        max-width: 330px;
      }
    }

    .bianu-promise {
      display: flex;
      margin-top: 31px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      h1 {
        margin-top: 9px;
        width: 100%;
      }

      p {
        font-size: 16px;
        line-height: 150%;
      }
    }

    .primary_button_green {
      max-width: 360px;
      width: 100%;
    }

    .payment_method_wrapper {
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .custom-input {
      margin-bottom: 16px;
    }

    &-addressTitle {
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 10px;
    }
  }


  &-cardInfo_date {
    margin: 16px 0 40px;
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;

    span {
      color: #88888e;
    }
  }

  &-cardInfo_address {
    display: grid;
    justify-content: space-between;
    grid-template: 1fr 1fr 1fr / 1fr 1fr;
    gap: 20px;
    position: relative;
  }

  .primary_button_green {
    margin-top: 40px;
  }

  .payment-secure_text {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #383942;
    margin-top: 12px;
    padding-left: 22px;

    &::before {
      position: absolute;
      content: '';
      background-image: url("../../assets/static/icon/secure-icon.svg");
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      top: 5px;
      left: 0;
    }
  }


  &-purchaseInfo-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    p {
      font-size: 16px;
      color: #383942;
    }
  }


  &-cardInfo_billingAddress {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 15px;

    label {
      color: $primary_one;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .footer-rec {
    margin-top: 15px;
  }
}

.card-infoDate-input {

  max-width: 191px;
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  background-color: $secondary_one;
  padding: 17px 0 17px 16px;
  border-radius: 8px;

  &:focus {
    border: 1px solid $primary_one;
  }
}

.ElementsApp {
  background-color: red;

  input {
    background-color: blue;
  }
}

.card-infoAddress-inputOne, .card-infoAddress-inputTwo, .card-infoCountry-input, {
  //max-width: 303px;
  width: 100%;
  grid-column: span 2;
  padding: 17px 0 17px 20px;
  font-size: 16px;
  border: 1px solid transparent;
  outline: none;
  border-radius: 8px;
  background-color: $secondary_one;

  &:focus {
    border: 1px solid $primary_one;
  }
}

.card-infoCountry-input {
  //max-width: 198px;
  width: 100%;
  grid-column: span 1;
}

.checkout-cardInfo-addressTitle {
  margin-top: 40px;
}

.Form {
  width: 100%;

  .payment-method-wrapper {
    grid-column: 1;
  }
}

.checkout-cardInfo-paymentTitle {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 28px;
}

.ElementsApp {
  background-color: blue;
}

.ErrorMessage {
  margin-top: 20px;
  max-width: 480px;
  height: 40px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $danger_color;
}

.promocode-input-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: -20px;

  .promocode_error {
    position: absolute;
    top: -22px;
    color: $danger_color;
    font-size: 13px;
    font-family: GilroyBold,sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }

  .promocode_title {
    display: block;
  }

  .promocode_form {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    position: relative;

    button {
      max-width: 94px;
      width: 100%;
      margin: 0;
      padding: 16px;
      height: min-content;
    }

    input {
      max-width: 330px;
      width: 100%;
      margin: 0;
      padding: 15px 20px 15px 20px;
    }
  }

}


@media (max-width: 600px) {
  .checkout {
    .footer-rec {
      display: none;
    }
    .checkout-cardInfo .checkout-cardInfo_footer .bianu-promise img{
      display: none;
    }
    .footer_main{
      display: none;
    }
  }
  .autocomplete_items {
    top: 77%;
    left: 0;
    max-height: 120px;
  }

  .checkout-cardInfo {
    .checkout-cardInfo_general_inputs {
      .promocode_title {
        display: block;
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: 700;
      }
    }
  }


  .checkout-cardInfo {
    margin: 30px 0;
    display: flex;
    flex-direction: column;


    .checkout-cardInfo_address_inputs, .checkout-cardInfo_general_inputs, .checkout-cardInfo_footer {
      gap: 20px;
    }

    .checkout-cardInfo_footer {
      margin-bottom: 20px;
    }

    .payment_account_buttons {
      align-items: center;

      button {
        width: 50%;
      }
    }

    .primary_button_green {
      max-width: 100% !important;
      width: 100%;
      margin: 40px auto 0;
    }


    .payment-secure_text {
      text-align: center;
      padding-left: 20px;

      &::before {
        left: 0;
      }
    }
  }
  .checkout {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0 15px;

    h1 {
      text-align: center;
      font-size: 25px;
      line-height: 29px;
    }

    .change-plan-link {
      margin: 15px auto;
    }

    Input {
      max-width: 100%;
      width: 100%;
    }

  }
  .checkout-container {
    flex-direction: column;

    .submitWrapper {

      .payment-summary-container {
        max-width: 394px;
        width: 100%;
        min-width: 0;
      }
    }

    .payment-details-header {
      font-family: Gilroy, sans-serif;
      //margin: 40px auto 14px;
      margin-bottom: 15px !important;
      text-align: left;
      font-size: 31px;
      line-height: 36px;
      font-weight: 700;
    }

    .recommendation {


      .recommendation-program {
        &:before {
          display: none;
        }

        padding: 0;
        border: none;

        .recommendation-program_link {
          display: none;
        }

        .recommendation-program_items {
          .recommendation-program_item {
            display: flex;
            flex-direction: row;

            .program-item_title {
              font-family: Gilroy, sans-serif;
              font-size: 13px;
              margin-left: 10px;
              line-height: 19px;
              font-weight: 400;
            }

            img {
              max-width: 56px;
              min-height: 56px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .checkout-cardInfo_billingAddress {
    flex-direction: column;
    width: 100%;
.sameAddress_container{
  margin-bottom: 10px;

}
    .round_monthly {
      width: 0;
      margin-left: 22px;

    }

    div {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: inherit;
    }

    input {
      width: 15px;
    }
  }
  .input-container {
    #cardNumber {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  #card-exp, #card-cvc {
    width: 48%;
  }
  .checkout {
    .checkout_modal{
      .subscription_main{
        max-width: 92%;
        margin-top: 0;
      }
    }
  }


}
