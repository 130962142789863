.connect_feed_back{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 146px;
  font-family: Gilroy, sans-serif;

  .connect_logo{
    display: block;
    margin: 0 auto;
  }
  .back_button{
    display: flex;
    align-items: stretch;
    transform: translateY(-14px);
    img{
      height: 13px;
    }
    .back_button_text{
      margin-left: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .feed_back_container{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    .feed_back_container_text{
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }
    img{
      max-width: 320px;
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .connect_feed_back{
    padding: 15px 30px;
    .back_button{
      transform: translateY(-25px);
    }
  }
}