
.header-rec{
  max-width: 1221px;
  width: 100%;
  padding: 24px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bianu_logo_container{
    margin-right: 84px;
  }
  &-logo{
    margin: 0 auto;
    transform: translateX(-40px);
    cursor: pointer;

    &NoTransform {
      @extend .header-rec-logo;
      transform: none;
    }
  }
  &-backBtn{
    cursor: pointer;
    display: flex;
    gap: 20px;
    max-width: 80px;
    width: 100%;
    align-items: baseline;
    p{
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 17px;
    }
  }
}


@media  (max-width: 380px){
  .header-rec{
    max-width: 375px;
    padding: 15px 20px;
  }
  .header-rec-backBtn{
    max-width: 70px;
  }
}
