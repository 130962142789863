.faq_container{
  max-width: 1150px;
  width: 100%;
  margin: 0 auto 84px;
  height: 100%;
  background: #FFFFFF;
}
.faq_item{
  background: #FFFFFF;
  border: 2px solid rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  padding: 20px 20px;
}
.faq_container_h1{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #383942;
  margin: 60px 0 40px 0;
}

@media (max-width: 600px) {
  .faq_container{
    margin: 0 auto 30px;
  }
  .header_main .container{
    padding: 0;
    max-width: 375px;
    width: 100%;
  }
  .faq_main{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .header_main_left p{
    display: none;
  }
  .secondary_button_white{
    display: none;
  }
.faq_container_h1 {
  margin: 20px 0 40px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #383942;
  }
}
