@import "../../../assets/styles/variable";
.order_account {
  border-radius: 16px;
  border: 1px solid rgba(196, 196, 196, 0.3);
  padding: 32px 32px;
  .end_subscription{
    margin-top: 28px;
    color: #BF2F29;
    cursor: pointer;
  }
}
.order_list{
  margin: 20px 0;
}
.order_account_title {
  font-weight: bold;
  font-size: 28px;
  color: $black;
  margin: 0 0 30px 0;
  text-align: left;
}
.order_account_top_wrapper {
  padding: 32px 32px;
  border-radius: 16px;
  background: rgba(233, 124, 24, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 17px;
  .loader_wrapper{
   img{
     max-width: 30px;
     width: 100%;
   }
  }
}
.loader_wrapper {
  text-align: center;
  //width: 100%;
  .loader_icon{
    max-width: 40px;
  }
}
.order_account_top_wrapper_right {
  width: 75%;
}
.order_account_top_wrapper_right_title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 10px;
}
.order_account_top_wrapper_right_name {
  h3 {
    font-weight: bold;
    font-size: 24px;
    color: $black;
    line-height: 30px;
    margin: 0 0 8px 0;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    color: $account_side_menu;
  }
}
.order_account_top_wrapper_right_button {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $white;
  padding: 3px 8px;
  border-radius: 12px;
  background-color: $button_order_account;
}
.order_account_top_wrapper_right_order_list {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 20px 0;
  li {
    font-size: 18px;
    color: #383942;
    line-height: 25px;
    font-family: GilroyRegular, sans-serif;
  }
  .first_item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

  }
}
.order_account_top_wrapper_right_control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    color: $secondary_two;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
      color: $primary_one_hover;
    }
    &:active {
      color: $primary_one_active;
    }
  }
  .end_subscription {
    color: $danger_color;
    transition: all .5s ease;
    &:hover {
      color: $danger_color;
    }
    &:active {
      color: $danger_color;
    }
  }
}
.order_account_title_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.order_account_title_bottom_left {
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 8px 0;
    color: $black;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: $account_side_menu;
  }
}
.order_account_sub_title{
  margin-bottom: 20px;
}
.order_account_title_bottom_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $black;
    margin:  0 14px 0 0 ;
  }
  img {
    cursor: pointer;
    transform: rotate(90deg);
  }
  .visible_menu {
    transform: rotate(-90deg);
  }
}

.order_account_cart_items {
  background-color: $secondary_one_hover;
  padding: 20px 20px;
  border-radius: 12px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: GilroyRegular, sans-serif;
  .loader_icon{
    display: block;
    margin: 0 auto;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 30px 0;
    font-family: GilroyRegular, sans-serif;
  }
}
.order_account_cart_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  .current-product{
    max-width: 84px;
    width: 100%;
    border-radius: 50%;
    height: 84px;
  }
  img {
    border-radius: 50%;
    margin-right: 12px;
    max-width: 84px;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    color: $black;
    margin: 0;
    font-family: GilroyRegular,sans-serif;
  }
  div {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $white;
    padding: 3px 8px;
    border-radius: 12px;
    background-color: $button_order_account;
  }
  .cart_item_delivering {
    background: $button_order_account;
  }
}
.order_account_cart_total_info {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
  font-family: GilroyRegular, sans-serif;
  .promo_code{
    color: #006C61;
    font-size: 16px;
    font-family: GilroyRegular, sans-serif;
    text-transform: uppercase;
  }
  div {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;
    p {
      font-family: GilroyRegular,sans-serif;

      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }
    span {
      font-weight: normal;
      font-size: 16px;
      font-family: GilroyRegular, sans-serif;
    }
  }
}
.order_account_cart_total_pay {
  div {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;
    p {
      font-family: GilroyRegular,sans-serif;

      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin: 0;
    }
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
.order_account_global_info_item {
  padding: 12px 0;
  border-top: 1px solid rgba(196, 196, 196, 0.3);
  text-align: left;
  color: $black;
  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 12px 0;

  }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    font-family: GilroyRegular, sans-serif;
  }
}
.no_subscription_wrapper {
  padding: 30px 30px;
  border-radius: 20px;
  background-color: $secondary_one_hover;
  margin-bottom: 20px;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 20px 0;
    color: $black;
  }
}
@media (max-width: 600px) {
  .order_account {
    padding: 32px 20px;
  }
  .order_account_top_wrapper {
    padding: 32px 20px;
    flex-direction: column;
    margin-bottom: 17px;
  }
  .order_account_top_wrapper_left {
    margin-bottom: 20px;
  }
  .order_account_top_wrapper_right {
    width: 100%;
  }
  .order_account_top_wrapper_right_control {
    flex-direction: column;
    div {
      margin-bottom: 20px;
    }
    .end_subscription {
      margin-bottom: 0;
    }
  }
  .order_account_cart_item {
    //flex-wrap: wrap;
    align-items: center;
    //align-items: flex-start;

    p {
      width: 45%;
    }
    div {
      //margin-left: 0;
      //margin-top: 10px;
    }
    .cart_item_delivering {
      background:#66ac5b;
    }
  }
}
