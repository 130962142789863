.multiple-form {
  h1 {
    width: 640px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .button-container {
    place-self: center;
    display: flex;
    max-width: 600px;
    flex-wrap: wrap;
    gap: 12px 20px;
    height: min-content;
    button {
      font-size: 18px;
      margin: 0;
      text-transform: none;
      width: auto;
    }
  }
  button {
    margin-top: 44px;
    width: 280px;
    place-self: center;
  }
  @media screen and (max-width: 601px) {
  .button-container {
    gap: 6px 10px;
    button {
      font-size: 10px;
      padding: 10px;
    }
  }
}
}
