.date-form {
  .multi-input {
    position: relative;
    small {
      position: absolute;
      bottom: -17.5px;
      left: 5px;
      margin: 0;
      color: red;
    }
  }
  .date-input {
    width: 126.67px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 0;
    height: 54px;

    &:nth-child(3) {
      margin-right: 0;
    }
  }

  .cycle-title {
    width: 100%;
    font-size: 18px;
  }

  .cycle-length {
    margin: 12px 0;
  }

  .cycle-input {
    text-align: center;
    height: 60px;
  }

  button {
    width: 400px
  }

  h1 {
    padding: 0;
    line-height: inherit;
    height: 40px;
  }

  a {
    margin-left: 4px;
    color: black;
    text-decoration: underline;
  }

  a:visited {
    color: black;
    text-decoration: 1px solid black;
  }

  .bottom-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 601px) {
    .date-input {
      width: calc((93vw - 20px) / 3);
    }
    .multi-input {
      margin-top: 20px;
    }
    button {
      place-self: center;
      width: 253px;
    }
  }
}
