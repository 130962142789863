.second_block_main_item{
  display: flex;
  max-width: 30%;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
  &:last-child {
    img {
      display: none;
    }
  }
}
.second_block_main_item_avatar{
  max-width: 165px;
  max-height: 165px;
  width:100%;
  height: 100%;
  margin: 15px 0;

}
.second_block_main_item_name{

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 125%;
  color: #383942;
  margin: 4px 0;
}
.second_block_main_item_position{

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136%;
  color: #383942;
  margin: 4px 0;
}
.second_block_main_item_about{

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #383942;
  margin: 16px 0;
}
@media (max-width: 600px) {
  .second_block_main{
    width: 100%;
  }
  .second_block_main_item{
    max-width: 100%;
  }
  .second_block_main_item_avatar{
    margin:60px 40px 50px 30px
  }
  .second_block_main_item_name{
    font-size: 22px;
    margin: 0 0 0 30px;
  }
  .second_block_main_item_position{
    font-size: 14px;
    margin: 4px 0 0 30px;

  }
  .second_block_main_item_about{
    width: 100%;
    font-size: 14px;
    margin: 16px 0 0 30px;
    padding-right: 15px;
    align-items: center;
  }
}
