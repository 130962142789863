$white: rgb(255, 255, 255);
$black: rgb(56, 57, 66);
$gray: rgb(32, 32, 32);
$primary_one: #006c61;
$primary_one_hover: #28977d;
$primary_one_active: rgb(3, 73, 66);
$primary_one_disabled: #006c61;
$primary_two: #4f7399;
$primary_two_hover: #729dca;
$primary_two_active: #3a5979;
$primary_two_disabled: #4f7399;
$secondary_one: #f5f5f5;
$secondary_one_hover: #f5f5f5;
$secondary_one_hover_text: #383942;
$secondary_one_active: rgba(196, 196, 196, 1);
$secondary_one_disabled: rgba(245, 245, 245, 1);
$secondary_two: #006c61;
$secondary_two_hover: #28977d;
$secondary_two_active: rgba(3, 73, 66, 1);
$secondary_two_disabled: rgba(245, 245, 245, 1);
$color: #606168;
$colorGrey : #c4c4c4;
$button_order_account: #66ac5b;
$button_order_account_status_color: #2bc0ff;
$border_color: rgba(196, 196, 196, 0.3);
$account_side_menu: #88888e;
$danger_color: #e03626;
$mobileBreakpoint: 676px;
$background_orangeLight: rgba(233, 124, 24, 0.1);
$invalid_border : #EB4D3E;
