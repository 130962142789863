@import "src/assets/styles/variable.scss";

.confirmation {
  max-width: 1145px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loader_icon {
    margin: 0 auto;
  }

  &_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px 0;

    img {
      max-width: 30px;
      width: 100%;
    }

    p {
      color: #606168;
      font-size: 16px;
      font-family: GilroyRegular, sans-serif;
    }
  }

  &-container {
    max-width: 1145px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &-items {

    display: flex;
    justify-content: space-between;
  }

  &-item_text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    font-family: GilroyRegular, sans-serif;
    text-align: left;
  }

  &-item {
    max-width: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-sub {
    text-align: center;
    background-color: #F3F8F7;
    padding: 30px 34px;
    max-width: 568px;
    width: 100%;
    border-radius: 16px;

  }

  &-sub_title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  &-sub_text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin: 15px 0;
  }

  &-item_img {
    max-width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  &_plus_icons {
    transform: translateY(-20px);
  }

  &-app {
    max-width: 568px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FCFCFC;
    padding: 40px 23px;
    border-radius: 12px;
    height: max-content;
    border: 2px solid #006C61;
  }

  &-app_buttons {
    max-width: 384px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 52px;
  }

  &-app_title {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    color: #383942;
    margin-bottom: 12px;
  }

  &-advantagesMobile {
    display: none;
  }

  &-advantages_title {
    margin: 0 0 24px;
  }

  &-advantages_container {
    max-width: 1145px;
    width: 100%;
    margin: 24px auto 100px;
    border-radius: 16px;
    background-color: #FCFCFC;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-advantages_item {
    max-width: 491px;
    width: 100%;
  }

  &-advantages_title {
    text-align: center;
    font-size: 25px;
  }

  &-app_text {
    margin: 18px 0 45px;
    font-size: 17px;
    line-height: 22px;
    color: #383942;
    font-family: GilroyRegular, sans-serif;

  }

  &-advantages_itemTitle {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 16px;
    font-weight: 700;
  }

  &-advantages_itemText {
    font-size: 13px;
    font-family: GilroyRegular, sans-serif;
    line-height: 21px;
    color: #383942;
    margin-bottom: 24px;
  }

  &-advantages_itemImages {
    display: flex;
    gap: 20px;

    div {
      padding: 13px 13px 8px;
      background-color: #f5f5f5;
      border-radius: 50%;
    }
  }

  &-app_content {
    background-image: url("../../assets/static/svg/iPhone_12_White_Hardware_Shell_Vertical_SCREEN__USEN 1.png");
    max-width: 321px;
    width: 100%;
    height: 424px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 17px 0;

    .confirmation-app_content_popUp {
      max-width: 100%;
      width: 100%;
      background: #FCFCFC;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
      border-radius: 0px 12px 12px 12px;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      padding: 28px 20px;

      p {
        font-size: 20px;
        line-height: 23px;
        color: #383943;
        font-weight: 700;
      }

      img {
        max-width: 87px;
        width: 100%;
        height: 87px;
      }
    }
  }
}


@media (max-width: 600px) {
  .confirmation {
    overflow-x: hidden;
    max-width: 550px;
    padding: 10px;

    &-container {
      flex-wrap: wrap;
    }

    &-sub {
      padding: 32px 8px 8px 8px;
    }

    &-items {
      flex-direction: row;

    }

    &-item {
      max-width: 70%;
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 10px auto;
      flex-direction: column;

      &_text {
        font-size: 10px;
        line-height: 15px;
        font-family: Gilroy, sans-serif;
      }

      .confirmation-item_img {
      }

    }

    &-app {
      padding: 30px 20px 40px;
      text-align: center;
      margin: 0;
    }

    &-app_title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }

    &-app_text {
      font-family: GilroySemi,sans-serif;
      font-size: 13px;
      line-height: 17px;
    }

    &-app_buttons {
      display: flex;
      gap: 20px;
    }

    &-app_button {
      width: 100%;
      height: 100%;
      align-items: flex-start;
    }

    &-advantages {
      display: none;

      &Mobile {
        display: block;
      }
    }

    &-advantages_container {
      max-width: 550px;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 20px;
    }

    &-advantages_item {
      margin-bottom: 35px;
    }

    &-advantages_itemImages {
      justify-content: space-between;
      align-items: center;
    }

    &_plus_icons {
      transform: translateY(0);
    }
  }
}
