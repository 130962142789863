.faq_item_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}
.faq_item{
  margin-bottom: 16px;
}
.faq_container_icon{
  width: 11px;
  height: 6px;
  transition: all .2s;

}
.faq_container_icon_rotate{
  width: 11px;
  height: 6px;
  transform: rotate(180deg);
  transition: all .2s;
}

.faq_item_h2{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #383942;
}
.faq_item_p{
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #383942;
}
@media (max-width: 600px) {
  .faq_item {
    margin-bottom: 10px;
  }
  .faq_item_h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #383942;
  }
  .faq_item_p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #383942;
  }
}