.quiz-form {
  position: absolute;
  top: 25%;
  height: min-content;
  width: fit-content;
  max-width: 650px;

  @media (max-width: 600px) {
    height: 100%;
  }

  .loader-container {
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .form-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
  }

  .registration_loader_wrapper {
    width: 100%;
    text-align: center;
  }

  * {
    margin-bottom: 20px;
  }

  .custom-input {
    width: 100%;
  }


  h1 {
    font-weight: bold;
    color: #383942;
    height: min-content;
    text-align: center;
    vertical-align: bottom;
    line-height: 2em;
  }

}

@media screen and (max-width: 601px) {
  .quiz-form {
    max-width: 93vw;
    top: 90px;
    margin-top: 90px;
    * {
      max-width: 93vw;
    }
  }
}
